<template>
  <div class="saas_index_body">
    <div id="home" class="title_banner">
      <div class="title_banner_body">
        <el-carousel :interval="5000" height="615px">
          <el-carousel-item>
            <div class="home_banner_bg">
              <!--              <div class="home_banner_bg_imgA"></div>-->
              <!--              <div class="home_banner_bg_imgB"></div>-->
              <img class="home_banner_bg_imgB" src="@/assets/saasImg/index/banner1@2x.jpg"/>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="discount_body">
        <template>
          <div v-for="(item, index) in discountList" :key="index" class="discount_body_item"
               @click="goActive(index)">
            <div class="discount_body_item_top">
              <img v-if="item.img" :src="item.img" style="width: 23px; height: 29px;"/>
              <div class="discount_body_item_top_title">{{ item.title }}</div>
            </div>
            <div class="discount_body_item_bottom">{{ item.msg }}</div>
          </div>
        </template>
      </div>
    </div>
    <div id="product" class="product">
      <div class="product_tab">
        <!-- <div class="product_tab_title">
           工具实用、资源丰富、自带数据、服务好得SaaS产品
         </div>-->
        <div id="product_saas_my" class="product_tab_body">
          <template>
            <div v-for="(item, index) in meunList" :key="index" class="product_tab_item"
                 @click="meunListTabClick(item)">
              <div class="product_tab_item_icon">
                <img v-if="item.select" :src="item.imgSel" style="width: 100%"/>
                <img v-else :src="item.img" style="width: 100%"/>
              </div>
              <div :class="{ menumTive: item.select }" class="product_tab_item_lab"> {{ item.name }}</div>
            </div>
          </template>
        </div>
      </div>
      <div class="product_body">
        <template>
          <div class="tenant_pro_body">
            <div class="tenant_pro_menum">
              <!--<div class="tenant_pro_title"> {{ item.tentInfo ? item.tentInfo.name : "" }}</div>-->
              <!--              <template>
                              <el-button v-for="(typeItem, typeIndex) in item.typeList" :key="typeIndex"
                                         class="tenant_pro_type_item"
                                         :class="{ tenant_pro_type_item_sel: typeItem.select }"
                                         @click="proTabClick(item, typeItem)">
                                {{ typeItem.name }}
                              </el-button>
                            </template>-->
            </div>
            <!--物流用户-->
            <div class="tenant_pro_cell">
              <template>
                <div class="tenant_pro_cell_item">
                  <!--物流用户展示-->
                  <div class="tenant_pro_cell_item_title_info" style="margin-left:-35px">
                    <div>
                      <img class="tenant_pro_cell_item_title_info_img" src="@/assets/saasImg/index/物流用户@2x.png"/>
                    </div>
                    <div class="tenant_pro_cell_item_info_title" style="">物流用户</div>
                  </div>
                  <div class="tenant_pro_cell_item_title" style="margin-left:-35px">
                    <div class="tenant_pro_cell_item_title_line"></div>
                    <div class="tenant_pro_cell_item_title_lab"></div>
                    <div class="tenant_pro_cell_item_title_line"></div>
                  </div>
                  <!--物流用户产品数据-->
                  <div class="tenant_pro_cell_item_body">
                    <template>
                      <div v-for="(proItem, proIndex) in logistics" :key="proIndex"
                           class="tenant_pro_cell_item_body_item">
                        <div class="textlineCss"></div>
                        <img alt="" class="bgCss" src="../assets/saasImg/index/样式2@2x.png">
                        <img alt="" class="imgCss" src="@/assets/saasImg/index/yinying.png">
                        <div class="tenant_pro_cell_item_body_item_name" @click="prductClick(proItem)">
                          {{ proItem.spName | ellipsisTitle }}
                        </div>
                        <div class="tenant_pro_cell_item_body_item_remark">{{ proItem.spRemark | ellipsisRemark }}</div>
                        <div style=" margin: 0 auto;">
                          <div class="tenant_pro_cell_item_body_item_tag">
                            <template>
                              <div v-for="(tagItem, tagIndex) in proItem.tagList"
                                   :key="tagIndex"
                                   class="tenant_pro_cell_item_body_item_tag_item">
                                {{ tagItem.title }}
                              </div>
                            </template>
                          </div>
                        </div>
                        <div v-if="proItem.unitPrice" class="tenant_pro_cell_item_body_item_price">
                          ￥{{ proItem.unitPrice  | ellipsisTitle }}
                          {{ proItem.unitName  | ellipsisTitle }}
                        </div>
                        <div v-else class="tenant_pro_cell_item_body_item_price" style="color: #6d737b;font-size: 27px"> 公测版</div>
                        <template
                            v-if="(proItem.spsStatusValue < 0 || proItem.spsStatusValue == null) && proItem.statusValue == 10 ">
                          <div class="tenant_pro_cell_item_body_item_info_nore">
                            <span class="tenant_pro_cell_item_body_item_info_left"></span>
                          </div>
                          <div v-if="proItem.spCode!='service_provider_sub'"
                               class="tenant_pro_cell_item_body_item_info">
                            <span class="tenant_pro_cell_item_body_item_info_left">{{ proItem.leftInfo }}</span>
                            <span class="tenant_pro_cell_item_body_item_info_left">{{ proItem.rightInfo }}</span>
<!--                                  v-html="proItem.rightInfo"></span>-->
                          </div>
                          <div v-if="proItem.spCode=='service_provider_sub'"
                               class="tenant_hove_show">
                            <div class="tenant_hove_show_subscription"
                                 @click.stop="gotoSubscribeAction(proItem)">立即入驻
                            </div>
                          </div>
                          <div v-else class="tenant_hove_show">
                            <div class="tenant_hove_show_subscription"
                                 @click.stop="gotoSubscribeAction(proItem)">立即订阅
                            </div>
                            <div class="tenant_hove_show_subscription_detail"
                                 @click.stop="prductClick(proItem)">订阅详情
                            </div>
                          </div>
                        </template>

                        <template v-else>
                          <div v-if="proItem.statusValue == 10"
                               class="tenant_pro_cell_item_body_item_info_nore">
                            <span class="tenant_pro_cell_item_body_item_info_left">{{ proItem.leftInfo }}</span>
                            <span class="tenant_pro_cell_item_body_item_info_left"
                                  v-html="proItem.rightInfo"></span>
                          </div>
                          <div v-if="proItem.statusValue == 1"
                               class="tenant_pro_cell_item_body_item_info_nore">
                            <span class="tenant_pro_cell_item_body_item_info_left">{{ proItem.statusName }}</span>
                            <span class="tenant_pro_cell_item_body_item_info_left"
                                  v-html="proItem.rightInfo"></span>
                          </div>
                        </template>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>

            <div class="middle_menu">
              <div class="" style="width:1266px;display: flex;">
                <!--承运商模式-->
                <div class="columnCss" style="margin-right:7px">
                  <div class="tenant_pro_cell_item_title_info">
                    <div>
                      <img class="tenant_pro_cell_item_title_info_img" src="@/assets/saasImg/index/chengyunshang.png"/>
                    </div>
                    <div class="tenant_pro_cell_item_info_title" style="">承运商模式</div>
                  </div>
                  <div class="tenant_pro_cell_item_title" style="margin-bottom:30px">
                    <div class="tenant_pro_cell_item_title_line"></div>
                    <div class="tenant_pro_cell_item_title_lab"></div>
                    <div class="tenant_pro_cell_item_title_line"></div>
                  </div>
                  <div v-if="carrier.length>0" class="middle_menuList">
                    <template>
                      <div v-for="(proItem, proIndex) in carrier" :key="proIndex"
                           class="tenant_pro_cell_item_body_item" style="margin-right:0px !important;">
                        <div class="textlineCss"></div>
                        <img alt="" class="bgCss" src="../assets/saasImg/index/样式2@2x.png">
                        <img alt="" class="imgCss" src="@/assets/saasImg/index/yinying.png">
                        <div class="tenant_pro_cell_item_body_item_name" @click="prductClick(proItem)">{{ proItem.spName | ellipsisTitle }}</div>
                        <div class="tenant_pro_cell_item_body_item_remark">{{ proItem.spRemark | ellipsisRemark }}</div>
                        <div style=" margin: 0 auto;">
                          <div class="tenant_pro_cell_item_body_item_tag">
                            <template>
                              <div v-for="(tagItem, tagIndex) in proItem.tagList"
                                   :key="tagIndex"
                                   class="tenant_pro_cell_item_body_item_tag_item">
                                {{ tagItem.title }}
                              </div>
                            </template>
                          </div>
                        </div>
                        <div v-if="proItem.unitPrice" class="tenant_pro_cell_item_body_item_price">
                          ￥{{ proItem.unitPrice  | ellipsisTitle }}
                          {{ proItem.unitName  | ellipsisTitle }}
                        </div>
                        <div v-else class="tenant_pro_cell_item_body_item_price" style="color: #6d737b;font-size: 27px"> 公测版</div>
                        <template
                            v-if="(proItem.spsStatusValue < 0 || proItem.spsStatusValue == null) && proItem.statusValue == 10 ">
                          <div class="tenant_pro_cell_item_body_item_info_nore">
                            <span class="tenant_pro_cell_item_body_item_info_left"></span>
                          </div>
                          <div v-if="proItem.spCode!='service_provider_sub'"
                               class="tenant_pro_cell_item_body_item_info">
                            <span class="tenant_pro_cell_item_body_item_info_left">{{ proItem.leftInfo }}</span>
                            <span class="tenant_pro_cell_item_body_item_info_left"
                                  v-html="proItem.rightInfo"></span>
                          </div>
                          <div v-if="proItem.spCode=='service_provider_sub'"
                               class="tenant_hove_show">
                            <div class="tenant_hove_show_subscription"
                                 @click.stop="gotoSubscribeAction(proItem)">立即入驻
                            </div>
                          </div>
                          <div v-else class="tenant_hove_show">
                            <div class="tenant_hove_show_subscription"
                                 @click.stop="gotoSubscribeAction(proItem)">立即订阅
                            </div>
                            <div class="tenant_hove_show_subscription_detail"
                                 @click.stop="prductClick(proItem)">订阅详情
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div v-if="proItem.statusValue == 10"
                               class="tenant_pro_cell_item_body_item_info_nore">
                            <span class="tenant_pro_cell_item_body_item_info_left">{{ proItem.leftInfo }}</span>
                            <span class="tenant_pro_cell_item_body_item_info_left"
                                  v-html="proItem.rightInfo"></span>
                          </div>
                          <div v-if="proItem.statusValue == 1"
                               class="tenant_pro_cell_item_body_item_info_nore">
                            <span class="tenant_pro_cell_item_body_item_info_left">{{ proItem.statusName }}</span>
                            <span class="tenant_pro_cell_item_body_item_info_left"
                                  v-html="proItem.rightInfo"></span>
                          </div>
                        </template>
                      </div>
                    </template>
                  </div>
                </div>
                <!--平台资源-->
                <div class="columnCss" style="margin-right:7px">
                  <div class="tenant_pro_cell_item_title_info">
                    <div>
                      <img class="tenant_pro_cell_item_title_info_img" src="@/assets/saasImg/index/pingtai.png"/>
                    </div>
                    <div class="tenant_pro_cell_item_info_title" style="">平台资源</div>
                  </div>
                  <div class="tenant_pro_cell_item_title" style="margin-bottom:30px">
                    <div class="tenant_pro_cell_item_title_line"></div>
                    <div class="tenant_pro_cell_item_title_lab"></div>
                    <div class="tenant_pro_cell_item_title_line"></div>
                  </div>
                  <div v-if="resources.length>0" class="middle_menuList">
                    <template>
                      <div v-for="(proItem, proIndex) in resources" :key="proIndex"
                           class="tenant_pro_cell_item_body_item" style="margin-right:0px !important;">
                        <div class="textlineCss"></div>
                        <img alt="" class="bgCss" src="../assets/saasImg/index/样式2@2x.png">
                        <img alt="" class="imgCss" src="@/assets/saasImg/index/yinying.png">
                        <div class="tenant_pro_cell_item_body_item_name" @click="prductClick(proItem)">{{ proItem.spName | ellipsisTitle }}</div>
                        <div class="tenant_pro_cell_item_body_item_remark">{{ proItem.spRemark | ellipsisRemark }}</div>
                        <div style=" margin: 0 auto;">
                          <div class="tenant_pro_cell_item_body_item_tag">
                            <template>
                              <div v-for="(tagItem, tagIndex) in proItem.tagList"
                                   :key="tagIndex"
                                   class="tenant_pro_cell_item_body_item_tag_item">
                                {{ tagItem.title }}
                              </div>
                            </template>
                          </div>
                        </div>
                        <div v-if="proItem.unitPrice" class="tenant_pro_cell_item_body_item_price">
                          ￥{{ proItem.unitPrice  | ellipsisTitle }}
                          {{ proItem.unitName  | ellipsisTitle }}
                        </div>
                        <div v-else class="tenant_pro_cell_item_body_item_price" style="color: #6d737b;font-size: 27px"> 公测版</div>
                        <template
                            v-if="(proItem.spsStatusValue < 0 || proItem.spsStatusValue == null) && proItem.statusValue == 10 ">
                          <div class="tenant_pro_cell_item_body_item_info_nore">
                            <span class="tenant_pro_cell_item_body_item_info_left"></span>
                          </div>
                          <div v-if="proItem.spCode!='service_provider_sub'"
                               class="tenant_pro_cell_item_body_item_info">
                            <span class="tenant_pro_cell_item_body_item_info_left">{{ proItem.leftInfo }}</span>
                            <span class="tenant_pro_cell_item_body_item_info_left"
                                  v-html="proItem.rightInfo"></span>
                          </div>
                          <div v-if="proItem.spCode=='service_provider_sub'"
                               class="tenant_hove_show">
                            <div class="tenant_hove_show_subscription"
                                 @click.stop="gotoSubscribeAction(proItem)">立即入驻
                            </div>
                          </div>
                          <div v-else class="tenant_hove_show">
                            <div class="tenant_hove_show_subscription"
                                 @click.stop="gotoSubscribeAction(proItem)">立即订阅
                            </div>
                            <div class="tenant_hove_show_subscription_detail"
                                 @click.stop="prductClick(proItem)">订阅详情
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div v-if="proItem.statusValue == 10"
                               class="tenant_pro_cell_item_body_item_info_nore">
                            <span class="tenant_pro_cell_item_body_item_info_left">{{ proItem.leftInfo }}</span>
                            <span class="tenant_pro_cell_item_body_item_info_left"
                                  v-html="proItem.rightInfo"></span>
                          </div>
                          <div v-if="proItem.statusValue == 1"
                               class="tenant_pro_cell_item_body_item_info_nore">
                            <span class="tenant_pro_cell_item_body_item_info_left">{{ proItem.statusName }}</span>
                            <span class="tenant_pro_cell_item_body_item_info_left"
                                  v-html="proItem.rightInfo"></span>
                          </div>
                        </template>
                      </div>
                    </template>
                  </div>
                </div>
                <!--自调车模式-->
                <div class="columnCss" style="margin-right:7px">
                  <div class="tenant_pro_cell_item_title_info">
                    <div>
                      <img class="tenant_pro_cell_item_title_info_img" src="@/assets/saasImg/index/zidiaoche.png"/>
                    </div>
                    <div class="tenant_pro_cell_item_info_title" style="">自调车模式</div>
                  </div>
                  <div class="tenant_pro_cell_item_title" style="margin-bottom:30px">
                    <div class="tenant_pro_cell_item_title_line"></div>
                    <div class="tenant_pro_cell_item_title_lab"></div>
                    <div class="tenant_pro_cell_item_title_line"></div>
                  </div>
                  <div v-if="shunting.length>0" class="middle_menuList">
                    <template>
                      <div v-for="(proItem, proIndex) in shunting" :key="proIndex"
                           class="tenant_pro_cell_item_body_item" style="margin-right:0px !important;">
                        <div class="textlineCss"></div>
                        <img alt="" class="bgCss" src="../assets/saasImg/index/样式2@2x.png">
                        <img alt="" class="imgCss" src="@/assets/saasImg/index/yinying.png">
                        <div class="tenant_pro_cell_item_body_item_name" @click="prductClick(proItem)">{{ proItem.spName | ellipsisTitle }}</div>
                        <div class="tenant_pro_cell_item_body_item_remark">{{ proItem.spRemark | ellipsisRemark }}</div>
                        <div style=" margin: 0 auto;">
                          <div class="tenant_pro_cell_item_body_item_tag">
                            <template>
                              <div v-for="(tagItem, tagIndex) in proItem.tagList"
                                   :key="tagIndex"
                                   class="tenant_pro_cell_item_body_item_tag_item">
                                {{ tagItem.title }}
                              </div>
                            </template>
                          </div>
                        </div>
                        <div v-if="proItem.unitPrice" class="tenant_pro_cell_item_body_item_price">
                          ￥{{ proItem.unitPrice  | ellipsisTitle }}
                          {{ proItem.unitName  | ellipsisTitle }}
                        </div>
                        <div v-else class="tenant_pro_cell_item_body_item_price" style="color: #6d737b;font-size: 27px"> 公测版</div>
                        <template
                            v-if="(proItem.spsStatusValue < 0 || proItem.spsStatusValue == null) && proItem.statusValue == 10 ">
                          <div class="tenant_pro_cell_item_body_item_info_nore">
                            <span class="tenant_pro_cell_item_body_item_info_left"></span>
                          </div>
                          <div v-if="proItem.spCode!='service_provider_sub'"
                               class="tenant_pro_cell_item_body_item_info">
                            <span class="tenant_pro_cell_item_body_item_info_left">{{ proItem.leftInfo }}</span>
                            <span class="tenant_pro_cell_item_body_item_info_left"
                                  v-html="proItem.rightInfo"></span>
                          </div>
                          <div v-if="proItem.spCode=='service_provider_sub'"
                               class="tenant_hove_show">
                            <div class="tenant_hove_show_subscription"
                                 @click.stop="gotoSubscribeAction(proItem)">立即入驻
                            </div>
                          </div>
                          <div v-else class="tenant_hove_show">
                            <div class="tenant_hove_show_subscription"
                                 @click.stop="gotoSubscribeAction(proItem)">立即订阅
                            </div>
                            <div class="tenant_hove_show_subscription_detail"
                                 @click.stop="prductClick(proItem)">订阅详情
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div v-if="proItem.statusValue == 10"
                               class="tenant_pro_cell_item_body_item_info_nore">
                            <span class="tenant_pro_cell_item_body_item_info_left">{{ proItem.leftInfo }}</span>
                            <span class="tenant_pro_cell_item_body_item_info_left"
                                  v-html="proItem.rightInfo"></span>
                          </div>
                          <div v-if="proItem.statusValue == 1"
                               class="tenant_pro_cell_item_body_item_info_nore">
                            <span class="tenant_pro_cell_item_body_item_info_left">{{ proItem.statusName }}</span>
                            <span class="tenant_pro_cell_item_body_item_info_left"
                                  v-html="proItem.rightInfo"></span>
                          </div>
                        </template>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <!--更多-->
            <div class="tenant_pro_cell">
              <template>
                <div class="tenant_pro_cell_item">
                  <!--更多展示-->
                  <div class="tenant_pro_cell_item_title_info" style="margin-left:-35px">
                    <div>
                      <img class="tenant_pro_cell_item_title_info_img" src="@/assets/saasImg/index/更多@2x.png"/>
                    </div>
                    <div class="tenant_pro_cell_item_info_title" style="">更多</div>
                  </div>
                  <div class="tenant_pro_cell_item_title" style="margin-left:-35px">
                    <div class="tenant_pro_cell_item_title_line"></div>
                    <div class="tenant_pro_cell_item_title_lab"></div>
                    <div class="tenant_pro_cell_item_title_line"></div>
                  </div>
                  <!--更多产品数据-->
                  <div class="tenant_pro_cell_item_body">
                    <template>
                      <div v-for="(proItem, proIndex) in other" :key="proIndex"
                           class="tenant_pro_cell_item_body_item">
                        <div class="textlineCss"></div>
                        <img alt="" class="bgCss" src="../assets/saasImg/index/样式2@2x.png">
                        <img alt="" class="imgCss" src="@/assets/saasImg/index/yinying.png">
                        <div class="tenant_pro_cell_item_body_item_name" @click="prductClick(proItem)">
                          {{ proItem.spName | ellipsisTitle }}
                        </div>
                        <div class="tenant_pro_cell_item_body_item_remark">{{ proItem.spRemark | ellipsisRemark }}</div>
                        <div style=" margin: 0 auto;">
                          <div class="tenant_pro_cell_item_body_item_tag">
                            <template>
                              <div v-for="(tagItem, tagIndex) in proItem.tagList"
                                   :key="tagIndex"
                                   class="tenant_pro_cell_item_body_item_tag_item">
                                {{ tagItem.title }}
                              </div>
                            </template>
                          </div>
                        </div>
                        <div v-if="proItem.unitPrice" class="tenant_pro_cell_item_body_item_price">
                          ￥{{ proItem.unitPrice  | ellipsisTitle }}
                          {{ proItem.unitName  | ellipsisTitle }}
                        </div>
                        <div v-else class="tenant_pro_cell_item_body_item_price" style="color: #6d737b;font-size: 27px"> 公测版</div>
                        <template
                            v-if="(proItem.spsStatusValue < 0 || proItem.spsStatusValue == null) && proItem.statusValue == 10 ">
                          <div class="tenant_pro_cell_item_body_item_info_nore">
                            <span class="tenant_pro_cell_item_body_item_info_left"></span>
                          </div>
                          <div v-if="proItem.spCode!='service_provider_sub'"
                               class="tenant_pro_cell_item_body_item_info">
                            <span class="tenant_pro_cell_item_body_item_info_left">{{ proItem.leftInfo }}</span>
                            <span class="tenant_pro_cell_item_body_item_info_left"
                                  v-html="proItem.rightInfo"></span>
                          </div>
                          <div v-if="proItem.spCode=='service_provider_sub'"
                               class="tenant_hove_show">
                            <div class="tenant_hove_show_subscription"
                                 @click.stop="gotoSubscribeAction(proItem)">立即入驻
                            </div>
                          </div>
                          <div v-else class="tenant_hove_show">
                            <div class="tenant_hove_show_subscription"
                                 @click.stop="gotoSubscribeAction(proItem)">立即订阅
                            </div>
                            <div class="tenant_hove_show_subscription_detail"
                                 @click.stop="prductClick(proItem)">订阅详情
                            </div>
                          </div>
                        </template>

                        <template v-else>
                          <div v-if="proItem.statusValue == 10"
                               class="tenant_pro_cell_item_body_item_info_nore">
                            <span class="tenant_pro_cell_item_body_item_info_left">{{ proItem.leftInfo }}</span>
                            <span class="tenant_pro_cell_item_body_item_info_left"
                                  v-html="proItem.rightInfo"></span>
                          </div>
                          <div v-if="proItem.statusValue == 1"
                               class="tenant_pro_cell_item_body_item_info_nore">
                            <span class="tenant_pro_cell_item_body_item_info_left">{{ proItem.statusName }}</span>
                            <span class="tenant_pro_cell_item_body_item_info_left"
                                  v-html="proItem.rightInfo"></span>
                          </div>
                        </template>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
      <div v-show="showExpect" class="product_body_empty">
        <span style="color:#ddd;font-size:32px;font-weight:500">敬请期待</span>
      </div>
    </div>

    <div id="custCase" class="cust_case">
      <div class="cust_title">客户案例</div>
      <div class="cust_body">
        <el-carousel :interval="5000" height="350px">
          <el-carousel-item v-for="(item, index) in custCaseList" :key="index">
            <div class="img_array_body">
              <img v-for="(imgItem, itemIndex) in item.imgs" :key="itemIndex"
                   :src="imgItem.img" class="img_array_body_item"/>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>


<script>
import aEntityAPI from "@/api/sysAPI.js";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {},
  computed: {
    ...mapGetters({
      sysConfig: "config/sysConfig"
    }),
    ...mapActions(["config/setConfig"])
  },

  data() {
    return {
      hrsaasImg: require("@/assets/hrsaas/index/hr_saas_logo.png"),
      saasImg: require("@/assets/saas_logo.png"),
      isLogin: false,
      showExpect: false,
      titleOrRemarkColor: false,
      topIndex: 1,
      searchValue: "",
      tenantDomain: "",
      selTenProTag: null, //当权选中工具，服务等的标志
      discountList: [
        {
          tag: 1,
          title: "满年8折",
          msg: "一次性订阅12月按8折收费",
          img: require("@/assets/saasImg/index/fire.png"),
        },

        {
          tag: 2,
          title: "满减钜惠",
          msg: "单次订单满2万按照8折计费",
          img: require("@/assets/saasImg/index/horn.png"),
        },
        // {
        //     tag: 3,
        //     title: "限时7折",
        //     msg: "工具类型产品按照7折计费",
        //     img: "",
        // },
      ],
      meunList: [
        {
          tag: 2,
          select: true,
          name: "运输类",
          code: "wlhy,tms",
          img: require("@/assets/saasImg/run_saas.png"),
          imgSel: require("@/assets/saasImg/run_saas_sel.png"),
        },
        {
          tag: 3,
          select: false,
          name: "人力类",
          code: "zbrl",
          img: require("@/assets/saasImg/zb_saas.png"),
          imgSel: require("@/assets/saasImg/zb_saas_sel.png"),
        },
      ],
      tentProList: [],
      logistics: [],
      carrier: [],
      resources: [],
      shunting: [],
      other: [],
      custCaseList: [
        {
          tag: 1,
          imgs: [
            {
              name: "元联供应链",
              img: require("@/assets/saasImg/cust/logo9.png"),
            },
            {
              name: "钱大妈",
              img: require("@/assets/saasImg/cust/logo02.png"),
            },
            {
              name: "胜利智运",
              img: require("@/assets/saasImg/cust/logo03.png"),
            },
            {
              name: "携运网",
              img: require("@/assets/saasImg/cust/logo04.png"),
            },
            {
              name: "网络货运",
              img: require("@/assets/saasImg/cust/logo05.png"),
            },
            {
              name: "中农网",
              img: require("@/assets/saasImg/cust/logo06.png"),
            },
            {
              name: "四通网运",
              img: require("@/assets/saasImg/cust/logo7.png"),
            },
            {
              name: "安胜物流",
              img: require("@/assets/saasImg/cust/logo8.png"),
            },
          ],
        },
        {
          tag: 2,
          imgs: [
            {
              name: "浩悦网络",
              img: require("@/assets/saasImg/cust/logo17.png"),
            },
            {
              name: "携运网",
              img: require("@/assets/saasImg/cust/logo10.png"),
            },
            {
              name: "德润供应链",
              img: require("@/assets/saasImg/cust/logo11.png"),
            },
            {
              name: "胜利智云",
              img: require("@/assets/saasImg/cust/logo12.png"),
            },
            {
              name: "泽运通物流",
              img: require("@/assets/saasImg/cust/logo13.png"),
            },
            {
              name: "琪旺",
              img: require("@/assets/saasImg/cust/logo14.png"),
            },
            {
              name: "海科共生",
              img: require("@/assets/saasImg/cust/logo15.png"),
            },
            {
              name: "熠路顺运输",
              img: require("@/assets/saasImg/cust/logo16.png"),
            },
          ],
        },
      ],
      productCodeImgList: [
        //工具包
        {
          code: "tool_online_wlhy",
          img: require("@/assets/saasImg/productImg/xianshangtiaoche.png"),
          name: "在线调车",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [
            {
              title: "移动",
            },
            {
              title: "实时",
            },
            {
              title: "便捷",
            },
          ],
        },
        {
          code: "tool_online_pay",
          img: require("@/assets/saasImg/productImg/xainshangjiesuan.png"),
          name: "在线结算",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [
            {
              title: "高效",
            },
            {
              title: "易用",
            },
            {
              title: "安全",
            },
          ],
        },
        {
          code: "tool_online_tracking",
          img: require("@/assets/saasImg/productImg/zaixiangenzong.png"),
          name: "在线质控",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [
            {
              title: "实时",
            },
            {
              title: "精准",
            },
            {
              title: "预警",
            },
          ],
        },
        {
          code: "tool_order",
          img: require("@/assets/saasImg/productImg/dingdanguanli.png"),
          name: "订单管理",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [
            {
              title: "移动",
            },
            {
              title: "智能",
            },
            {
              title: "实用",
            },
          ],
        },
        {
          code: "tool_online_dispatch",
          img: require("@/assets/saasImg/productImg/zxpd.png"),
          name: "在线派车",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [
            {
              title: "移动",
            },
            {
              title: "可靠",
            },
            {
              title: "及时",
            },
          ],
        },
        {
          code: "tool_tms_settle",
          img: require("@/assets/saasImg/productImg/jiesuaiguanli.png"),
          name: "结算管理",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [
            {
              title: "移动",
            },
            {
              title: "实用",
            },
            {
              title: "协同",
            },
          ],
        },
        {
          code: "tool_supplier",
          img: require("@/assets/saasImg/productImg/gysgl.png"),
          name: "供应商管理",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [],
        },
        {
          code: "tool_less_shipped",
          img: require("@/assets/saasImg/productImg/ldfy.png"),
          name: "零担发运",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [],
        },
        {
          code: "tool_contract_price",
          img: require("@/assets/saasImg/productImg/htjggl.png"),
          name: "合同智能计费",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [
            {
              title: "智能",
            },
            {
              title: "实时",
            },
            {
              title: "协同",
            },
          ],
        },
        {
          code: "tool_blling",
          img: require("@/assets/saasImg/productImg/kpgl.png"),
          name: "开票管理",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [],
        },
        {
          code: "tool_owned_car",
          img: require("@/assets/saasImg/productImg/zycgl.png"),
          name: "自有车管理",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [],
        },
        {
          code: "tool_bulk_freight",
          img: require("@/assets/saasImg/productImg/dzhy.png"),
          name: "大批量发运",
          proDesc: "针对大宗业务网络货运，实现批量发单，提高运作效率",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [
            {
              title: "高效",
            },
            {
              title: "实用",
            },
            {
              title: "协同",
            },
          ],
        },
        {
          code: "service_provider",
          img: require("@/assets/saasImg/productImg/wlhy_fws.png"),
          name: "服务商服务",
          proDesc: "可开通服务商账号，提供线上开票服务",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [
            {
              title: "自由选择",
            },
            {
              title: "降本增效",
            },
          ],
        },
        {
          code: "tool_big_data",
          img: require("@/assets/saasImg/productImg/jyksh.png"),
          name: "网络货运大数据可视化",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [
            {
              title: "可配置",
            },
            {
              title: "易使用",
            },
            {
              title: "美观",
            },
          ],
        },
        {
          code: "e-bidding",
          img: require("@/assets/saasImg/productImg/ebidding.png"),
          name: "数字招投标",
          proFeature: [
            {
              title: "智能",
            },
            {
              title: "便捷",
            },
            {
              title: "实用",
            },
          ],
        },

        //资源
        {
          code: "resource_oil_card",
          img: require("@/assets/saasImg/productImg/yk.png"),
          name: "油卡",
          tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [],
        },
        {
          code: "resource_etc_ticket",
          img: require("@/assets/saasImg/productImg/etc.png"),
          name: "ETC",
          tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [
            {
              title: "易用",
            },
          ],
        },
        {
          code: "resource_signature",
          img: require("@/assets/saasImg/productImg/dzqz.png"),
          name: "电子签章",
          tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [
            {
              title: "安全",
            },
            {
              title: "易用",
            },
          ],
        },
      
        {
          code: "resource_insurance",
          img: require("@/assets/saasImg/productImg/bx.png"),
          name: "保险",
          tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [],
        },
        {
          code: "resource_bank",
          img: require("@/assets/saasImg/productImg/yqtg.png"),
          name: "银企托管",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [
            {
              title: "安全",
            },
            {
              title: "稳定",
            },
            {
              title: "省心",
            },
          ],
        },
        {
          code: "resource_dri_levy",
          img: require("@/assets/saasImg/productImg/sjdz.png"),
          name: "司机代征",
          tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [
            {
              title: "易用",
            },
          ],
        },

        //数据
        {
          code: "data_,dingwei,guiji,trace_forSaas",
          img: require("@/assets/saasImg/productImg/bdzb.png"),
          name: "北斗定位",
          tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [],
        },
        {
          code: "data_,send_sms",
          img: require("@/assets/saasImg/productImg/dx.png"),
          name: "短信服务",
          tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [],
        },
        {
          code: "data_,Insurance_service",
          img: require("@/assets/saasImg/productImg/bdzb.png"),
          name: "保险服务商入驻",
          tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [
            {
              title: "共生共赢",
            },
            {
              title: "规模效应",
            },
            {
              title: "操作便捷",
            },
          ],
        },
        {
          code: "block_chain",
          img: require("@/assets/saasImg/productImg/gcfw.png"),
          name: "数据上链",
          //tagImg:require("@/assets/saasImg/sup_tag.png"),
          proFeature: [
            {
              title: "增信",
            },
            {
              title: "可靠",
            },
            {
              title: "安全",
            },
          ],
        },
        //服务
        {
          code: "resource_insured",
          img: require("@/assets/saasImg/productImg/dzqz.png"),
          name: "在线投保",
          tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [
            {
              title: "灵活",
            },
            {
              title: "可靠",
            },
            {
              title: "便捷",
            },
          ],
        },
        {
          code: "serve_creation",
          img: require("@/assets/saasImg/productImg/gcfw.png"),
          name: "共创服务",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [],
        },
        {
          code: "serve_online_help",
          img: require("@/assets/saasImg/productImg/wlhysbxz.png"),
          name: "网络货运申报协助",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [],
        },
        {
          code: "serve_online_retest",
          img: require("@/assets/saasImg/productImg/wlhycfc.png"),
          name: "网络货运等保复测",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [],
        },
        {
          code: "serve_ICP",
          img: require("@/assets/saasImg/productImg/ICP.png"),
          name: "ICP电信增值年审",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [],
        },
        {
          code: "service_provider_sub",
          img: require("@/assets/saasImg/productImg/网络货运服务商.png"),
          name: "网络货运服务商入驻",
          // tagImg: require("@/assets/saasImg/sup_tag.png"),
          proFeature: [
            {
              title: "共生共赢",
            },
            {
              title: "良性机制",
            },
          ],
        },
      ],
      typeList: [
        // {tag: 0, select: true, name: "全部", value: null},
        // {tag: 1, select: false, name: "工具", value: 1},
        // {tag: 2, select: false, name: "数据", value: 2},
        // {tag: 3, select: false, name: "资源", value: 3},
        // {tag: 4, select: false, name: "服务", value: 4},

        // {tag: 1, select: false, name: "工具", value: 1},
        // {tag: 2, select: false, name: "在线服务", value: 2},
        // {tag: 3, select: false, name: "线下服务", value: 3},//数据 线上
      ],
    };
  },

  created() {
    this["config/setConfig"];
  },

  watch: {
    sysConfig: function (nval, oval) {
      if (nval && nval.logoFile && nval.logoFile.filePath) {
        var link = document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        var serverHost = window.location.host;
        if (serverHost.indexOf("hrsaas") > -1 || serverHost.indexOf("saas.gz-vip.com") > -1) {
          link.href = this.hrsaasImg;
        } else if (serverHost.indexOf("saas") > -1) {
          link.href = this.saasImg;
        } else {
          link.href = this.__ftpPath + nval.logoFile.filePath;
        }
        document.getElementsByTagName("head")[0].appendChild(link);
      }
    }
  },

  mounted() {

    if (this.$route.params && this.$route.params.searchValue != null) {
      this.searchValue = this.$route.params.searchValue;
    }
    this.getSaasIndex(null, 0)

    aEntityAPI.saasRequest("UserProductSubscribeService", "saasIndexConfig").then((res) => {
      if (res.data.success && res.data.data.user != null) {
        this.isLogin = true
      }
    });

  },

  methods: {
    mouseOver() {
      this.titleOrRemarkColor = true;
    },

    goActive(index) {

      this.topIndex = 2;

      if (index == 0) {

        this.$router.push({
          name: "saasActive", params: {
            index: index
          }
        });
      }
      if (index == 1) {
        this.$router.push({
          name: "saasActive", params: {
            index: index
          }
        });
      }
      if (index == 2) {
        this.$router.push({
          name: "saasActive", params: {
            index: index
          }
        });
      }

    },

    gotoSubscribeAction(proItem) {
      var url = null;
      if (this.isLogin) {
        url = window.location.protocol + "//" + window.location.host + "/saas56.html#/saasSubscribeTab/" + proItem.spId + "/" + proItem.spCode;
        window.open(url)
      } else {
        sessionStorage.setItem("beforeLoginUrl", "/saas56.html#/saasSubscribeTab/" + proItem.spId + "/" + proItem.spCode);
        url = window.location.protocol + "//" + window.location.host + "/login.html#/login/saas?fromUrl=" + encodeURIComponent(window.location.href);
        window.location.href = url
      }
    },


    //点击了产品分类
    meunListTabClick(itemSel) {
      this.showExpect = itemSel.code != 'wlhy,tms' ? true : false;
      this.meunList.forEach((item) => {
        if (item.code == itemSel.code) {
          item.select = true;
        } else {
          item.select = false;
        }
      });
      this.selTenProTag = null;
      //重新获取产品详情的数据
      this.getSaasIndex(itemSel.code, 1);
    },

    //点击了对应的产品
    prductClick(item) {
      var url = window.location.protocol + "//" + window.location.host + "/saas.html#/saasDetail/" + item.spId + "?spId=" + item.spId + "&code=" + item.spCode;
      window.open(url);
    },

    //点击了单独的产品的类型
    proTabClick(tentItem, typeItem) {
      var that = this;
      that.tentProList.forEach((tItem) => {
        if (tentItem.typeList != null) {
          tentItem.typeList.forEach((type) => {
            if (type.value == typeItem.value) {
              type.select = true;
              if (typeItem.value == null || typeItem.value == "") {
                that.selTenProTag = null;
              } else {
                that.selTenProTag = tentItem.tentInfo.name + typeItem.name;
              }
            } else {
              type.select = false;
            }
          });
        }
      });
    },

    //获取产品对应的图片
    getProImg(item) {

      item.proBgImg = this.productCodeImgList[0].img;
      item.tagImg = "";
      item.tagList = [];
      if (
          (item.spName != null && item.spName != "") ||
          (item.spCode != null && item.spCode != "")
      ) {
        this.productCodeImgList.forEach((temp) => {
          if (
              (temp.code != null && temp.code.indexOf(item.spCode) != -1) ||
              temp.name == item.spName
          ) {
            item.proBgImg = temp.img;
            item.tagImg = temp.tagImg;
            item.tagList =
                temp.proFeature != null && temp.proFeature.length > 0
                    ? temp.proFeature
                    : [];
          }
        });
      }
      return item;
    },

    //获取saas首页的数
    getSaasIndex(productCode, loadTag) {
      var params = {};
      if (productCode != null && productCode != "") {
        params.productCode = productCode;
      }
      if (this.searchValue != null && this.searchValue != "") {
        params.searchValue = this.searchValue;
        document.getElementById("product_saas_my").scrollIntoView(true);
      }
      aEntityAPI.saasRequest("UserProductSubscribeService", "querySaasIndex", params).then((res) => {
        this.grid_loading = false;
        if (res.data.success) {
          var data = res.data.data;
          //物流用户
          var logistics = data.logistics;
          if (logistics.length > 0) {
            console.log("进到物流用户")
            logistics.forEach((item) => {
              item = this.getProImg(item);
              item.leftInfo = item.spsStatusName;
              item.rightInfo = "";
              if (item.spsStatusValue == 7) {
                item.leftInfo = "体验版";
                if (item.endNowNum >= 0) {
                  //
                  item.rightInfo = '<span>剩余<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + item.endNowNum + '</span>天</span>';
                } else {
                  item.rightInfo = '<span>过期<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + (-item.endNowNum) + '</span>天</span>';
                }
              }
              if (item.spsStatusValue == 5 && item.subMonths != null) {
                if (item.subMonths >= 0) {
                  item.rightInfo = '<span>剩余<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + item.subMonths + '</span>月</span>';
                } else {
                  item.rightInfo = '<span>过期<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + (-item.subMonths) + '</span>月</span>';
                }
              }
              if (item.spsStatusValue == null || item.spsStatusValue == -3) {
                item.leftInfo = "未订阅";
              }
            });
            this.logistics = logistics;
          }

          //承运商模式
          var carrier = data.carrier;
          this.carrier = carrier;
          //平台资源
          var resources = data.resources;
          this.resources = data.resources
          //自调车模式
          var shunting = data.shunting;
          this.shunting = data.shunting;
          //更多
          var other = data.other;
          if (other.length > 0) {
            other.forEach((item) => {
              item = this.getProImg(item);
              item.leftInfo = item.spsStatusName;
              item.rightInfo = "";
              if (item.spsStatusValue == 7) {
                item.leftInfo = "体验版";
                if (item.endNowNum >= 0) {
                  //
                  item.rightInfo = '<span>剩余<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + item.endNowNum + '</span>天</span>';
                } else {
                  item.rightInfo = '<span>过期<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + (-item.endNowNum) + '</span>天</span>';
                }
              }
              if (item.spsStatusValue == 5 && item.subMonths != null) {
                if (item.subMonths >= 0) {
                  item.rightInfo = '<span>剩余<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + item.subMonths + '</span>月</span>';
                } else {
                  item.rightInfo = '<span>过期<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + (-item.subMonths) + '</span>月</span>';
                }
              }
              if (item.spsStatusValue == null || item.spsStatusValue == -3) {
                item.leftInfo = "未订阅";
              }
            });
            this.other = other;
          }

          if (carrier.length > 0) {
            carrier.forEach((item) => {
              item = this.getProImg(item);
              item.leftInfo = item.spsStatusName;
              item.rightInfo = "";
              if (item.spsStatusValue == 7) {
                item.leftInfo = "体验版";
                if (item.endNowNum >= 0) {
                  //
                  item.rightInfo = '<span>剩余<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + item.endNowNum + '</span>天</span>';
                } else {
                  item.rightInfo = '<span>过期<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + (-item.endNowNum) + '</span>天</span>';
                }
              }
              if (item.spsStatusValue == 5 && item.subMonths != null) {
                if (item.subMonths >= 0) {
                  item.rightInfo = '<span>剩余<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + item.subMonths + '</span>月</span>';
                } else {
                  item.rightInfo = '<span>过期<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + (-item.subMonths) + '</span>月</span>';
                }
              }
              if (item.spsStatusValue == null || item.spsStatusValue == -3) {
                item.leftInfo = "未订阅";
              }
            });
            this.carrier = carrier;
          }

          if (resources.length > 0) {
            resources.forEach((item) => {
              item = this.getProImg(item);
              item.leftInfo = item.spsStatusName;
              item.rightInfo = "";
              if (item.spsStatusValue == 7) {
                item.leftInfo = "体验版";
                if (item.endNowNum >= 0) {
                  //
                  item.rightInfo = '<span>剩余<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + item.endNowNum + '</span>天</span>';
                } else {
                  item.rightInfo = '<span>过期<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + (-item.endNowNum) + '</span>天</span>';
                }
              }
              if (item.spsStatusValue == 5 && item.subMonths != null) {
                if (item.subMonths >= 0) {
                  item.rightInfo = '<span>剩余<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + item.subMonths + '</span>月</span>';
                } else {
                  item.rightInfo = '<span>过期<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + (-item.subMonths) + '</span>月</span>';
                }
              }
              if (item.spsStatusValue == null || item.spsStatusValue == -3) {
                item.leftInfo = "未订阅";
              }
            });
            this.resources = resources;
          }

          if (shunting.length > 0) {
            shunting.forEach((item) => {
              item = this.getProImg(item);
              item.leftInfo = item.spsStatusName;
              item.rightInfo = "";
              if (item.spsStatusValue == 7) {
                item.leftInfo = "体验版";
                if (item.endNowNum >= 0) {
                  //
                  item.rightInfo = '<span>剩余<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + item.endNowNum + '</span>天</span>';
                } else {
                  item.rightInfo = '<span>过期<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + (-item.endNowNum) + '</span>天</span>';
                }
              }
              if (item.spsStatusValue == 5 && item.subMonths != null) {
                if (item.subMonths >= 0) {
                  item.rightInfo = '<span>剩余<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + item.subMonths + '</span>月</span>';
                } else {
                  item.rightInfo = '<span>过期<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + (-item.subMonths) + '</span>月</span>';
                }
              }
              if (item.spsStatusValue == null || item.spsStatusValue == -3) {
                item.leftInfo = "未订阅";
              }
            });
            this.shunting = shunting;
          }

          /*  if (tentProList != null && tentProList.length > 0) {
              tentProList.forEach((tentItem) => {
                var productList = tentItem.productList;
                tentItem.typeList = JSON.parse(JSON.stringify(this.typeList));
                productList.forEach((itemT) => {
                  if (itemT.items != null && itemT.items.length > 0) {
                    itemT.items.forEach((item) => {
                      item = this.getProImg(item);
                      item.leftInfo = item.spsStatusName;
                      item.rightInfo = "";
                      if (item.spsStatusValue == 7) {
                        item.leftInfo = "体验版";
                        if (item.endNowNum >= 0) {
                          //
                          item.rightInfo = '<span>剩余<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + item.endNowNum + '</span>天</span>';
                        } else {
                          item.rightInfo = '<span>过期<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + (-item.endNowNum) + '</span>天</span>';
                        }
                      }
                      if (item.spsStatusValue == 5 && item.subMonths != null) {
                        if (item.subMonths >= 0) {
                          item.rightInfo = '<span>剩余<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + item.subMonths + '</span>月</span>';
                        } else {
                          item.rightInfo = '<span>过期<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + (-item.subMonths) + '</span>月</span>';
                        }
  
                      }
                      if (item.spsStatusValue == null) {
                        item.leftInfo = "未订阅";
                      }
  
                    });
                  }
                });
              });
              this.tentProList = tentProList;
            } else {
              this.tentProList = [];
            }*/
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg ? res.data.msg : "查询产品订阅数据失败",
            type: "warning",
          });
        }
      })
          .catch((res) => {
            this.grid_loading = false;
          });
    },
  },

  filters: {
    //文字数超出时，超出部分使用...
    ellipsisTitle(value) {
      if (!value) return ''
      if (value.length > 15) {
        return value.slice(0, 15) + '...'
      }
      return value
    },

    ellipsisRemark(value) {
      if (!value) return ''
      if (value.length > 35) {
        return value.slice(0, 35) + '...'
      }
      return value
    }
  }
};
</script>

<style>
.saas_index_body {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.saas_index_body .home_banner_bg {
  width: 100%;
  height: 100%;
  position: relative;
}

.saas_index_body .home_banner_bg_imgA {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.saas_index_body .home_banner_bg_imgB {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  mix-blend-mode: screen;
  z-index: 4;
  /* object-fit: contain; */
}

.saas_index_body .home_banner_bg_imgC {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  object-fit: contain;
}

.saas_index_body .title_banner {
  padding-top: 55px;
  height: 580px;
  background-color: #ffffff;
}

.saas_index_body .discount {
  height: 250px;
  background-color: #0f68e8;
}

.saas_index_body .product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.saas_index_body .product_tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
  display: none;
}

.saas_index_body .product_tab_title {
  font-size: 35px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #393939;
  line-height: 50px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 50px;
}

.saas_index_body .product_tab_body {
  width: 50%;
  /*padding: 45px;*/
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;
}

.saas_index_body .product_tab_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.saas_index_body .product_tab_item .menumTive {
  border-bottom: 3px #0f68e8 solid;
}

.saas_index_body .product_tab_item_icon {
  width: 76px;
  height: 83px;
}

.product_tab_item:hover {
  cursor: pointer;
}

.saas_index_body .product_tab_item_lab {
  font-size: 18px;
  padding-bottom: 15px;
  padding-top: 35px;
  text-align: center;
  width: 100%;
}

.saas_index_body .product_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 60px;
  background-color: #f5f7faff;
}

.saas_index_body .product_body_empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 260px;
  width: 100%;
  background-color: #f5f7faff;
}

.saas_index_body .tenant_pro_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 80px;
}

.saas_index_body .tenant_pro_title {
  font-size: 32px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #000000;
  margin-right: 33px;
}

.saas_index_body .tenant_pro_menum {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.saas_index_body .tenant_pro_type_item {
  font-size: 24px !important;
  font-family: Source Han Sans SC !important;
  font-weight: 500 !important;
  color: #333333 !important;
  background-color: #ffffff !important;
  text-align: center !important;
  margin-left: 50px !important;
  padding: 10px 40px !important;
  box-shadow: 0px 2px 10px #1571b342 !important;
}

.saas_index_body .tenant_pro_type_item_sel {
  color: #ffffff !important;
  background-color: #2468f2ff !important;
}

.saas_index_body .tenant_pro_type_item:hover {
  color: #ffffff !important;
  background-color: #2468f2ff !important;
}

.saas_index_body .tenant_pro_type_item_sel {
  color: #e5e5e5;
  background-color: #808080;
}

.saas_index_body .tenant_pro_cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 75px;
}

.saas_index_body .tenant_pro_cell_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.saas_index_body .tenant_pro_cell_item_title_info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 10px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
}

.tenant_pro_cell_item_title_info .tenant_pro_cell_item_title_info_img {
  width: 60px;
  height: 60px;
  margin-left: -66px;
}

.tenant_pro_cell_item_title_info .tenant_pro_cell_item_info_title {
  color: #1C1A1C;
  font-weight: 500;
  font-size: 30px;
  font-family: Source Han Sans SC;
  color: #383838;
  margin-left: 5px;
}


.saas_index_body .tenant_pro_cell_item_title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 40px;
}

.saas_index_body .tenant_pro_cell_item_title_line {
  width: 175px;
  height: 1px;
  background-color: #1e84ff;
}

.saas_index_body .tenant_pro_cell_item_title_lab {
  color: #505050;
  /*font-size: 18px;*/
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 400;
  width: 5px;
  height: 5px;
  background-color: #1e84ff;
}

.saas_index_body .tenant_pro_cell_item_body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 1266px;
  padding-left: 30px;
}

.saas_index_body .tenant_pro_cell_item_body_item {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 378px;
  height: 471px;
  box-shadow: 0px 0px 10px 0px rgba(188, 188, 188, 0.56);
  /*opacity: 0.32;*/
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  margin-bottom: 30px;
  margin-right: 30px;
  /*background-color: #FFFFFF;*/
  /*border: 2px solid #FFFFFF;*/
  /* box-shadow: 0px 10px 10px 0px rgba(188,188,188,0.56); */
  /* background-image: url(""); */
  /*background-right: repeat;*/
  /* background-size: 100% 100%; */
  position: relative;
  /* z-index: 1; */
}

.textlineCss {
  width: 30%;
  height: 4px;
  /* background: #2A97E1; */
  position: absolute;
  top: 0;
  left: 35%;
  z-index: 2;
}

.bgCss {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.imgCss {
  width: 378px;
  /* height: 35px; */
  position: absolute;
  bottom: -50px;
  z-index: 0;
}

.saas_index_body .tenant_pro_cell_item_body_item_cont {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin: -25px 5% 3% 5%;
  width: 90%;
  z-index: 2;
}

.saas_index_body .tenant_pro_cell_item_body_item_title {
  /*font-size: 21px;*/
  color: #000000;
  /*font-weight: bold;*/
  position: absolute;
  width: 97%;
  left: 10px;
  top: 0;
  z-index: 2;
}

.saas_index_body .tenant_pro_cell_item_body_item_name {
  /*font-size: 21px;*/
  /*color: #303030;*/
  /*font-weight: 600;*/
  /*float: left;*/

  /*width: 200px;*/
  /*height: 39px;*/
  font-size: 35px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #000000;
  margin: 0 auto;
  margin-top: 15px;
  z-index: 2;
}

.saas_index_body .tenant_pro_cell_item_body_item_price {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  height: 29px;
  font-size: 30px;
  font-family: Adobe Heiti Std;
  font-weight: 525;
  color: #000000;
  margin-top: -30px;
  z-index: 2;
}

.saas_index_body .tenant_pro_cell_item_body_item_tag {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
  margin: 0 auto;
  margin-top: -30px;
  z-index: 2;
}

.saas_index_body .tenant_pro_cell_item_body_item_tag_item {
  width: 30%;
  border: 1px #2a82e4 solid;
  color: #2a82e4;
  padding: 3px 0;
  text-align: center;
  margin-right: 5px;
  z-index: 999;
}

.saas_index_body .tenant_pro_cell_item_body_item_remark {
  /*color: #505050;*/
  /*width: 100%;*/
  /*font-size: 14px;*/
  /*line-height: 24px;*/
  /*height: 50px;*/
  /*!*height: auto;*!*/
  /*word-break: break-all;*/
  /*overflow: hidden;*/
  /*margin-top: 32px;*/

  width: 250px;
  height: 50px;
  font-size: 14px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #999999;
  margin: 0 auto;
  margin-top: -30px;
  z-index: 2;
}

.saas_index_body .tenant_pro_cell_item_body_item_info {
  color: #505050;
  width: inherit;
  font-size: 14px;
  height: 40px;
  /*line-height: 40px;*/
  opacity: 1;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  transition: 0.3s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  z-index: 2;
  position: absolute;
  left: 40px;
  bottom: 45px;
}

.saas_index_body .tenant_pro_cell_item_body_item_info_nore {
  color: #505050;
  margin-left: 40px;
  margin-right: 20px;
  margin-bottom: 10px;
  width: 80%;
  /*width: inherit;*/
  font-size: 14px;
  line-height: 40px;
  height: 40px;
  opacity: 1;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  /*margin-top: 8px;*/
}

.saas_index_body .tenant_hove_show {

  /*display: flex;*/
  /*flex-direction: row;*/
  /*align-items: center;*/
  /*justify-content: center;*/

  /*padding-left: 20px;*/
  /*padding-right: 20px;*/

  position: absolute;
  /*background-color: #2a82e4;*/
  color: #fff;
  font-size: 16px;
  height: 0px;
  bottom: 35px;
  width: 100%;
  line-height: 38px;
  overflow: hidden;
  text-align: center;
  opacity: 0;
  transition: 0.3s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  z-index: 2;
  display: flex;
  justify-content: space-between;
}

.saas_index_body .tenant_hove_show_subscription {
  z-index: 2;
  float: left;
  color: #ffffff;
  background-color: #2a82e4;
  font-size: 16px;
  width: 40%;
  margin-left: 30px;
  /*height: 0px;*/
  /*bottom: 0;*/
  /*width: 100%;*/
  /*line-height: 40px;*/
  /*overflow: hidden;*/
}

.saas_index_body .tenant_hove_show_subscription_detail {
  z-index: 2;
  float: right;
  color: #2a82e4;
  background-color: #FFFFFF;
  font-size: 16px;
  width: 40%;
  height: 36px;
  border: 1px solid #2a82e4;
  margin-right: 30px;
  /*height: 0px;*/
  /*bottom: 0;*/
  /*width: 100%;*/
  /*line-height: 40px;*/
  /*overflow: hidden;*/
}

.saas_index_body .tenant_pro_cell_item_body_item:hover {
  box-shadow: 0px 0px 5px #6d737b;
  /* background-image: url("../assets/saasImg/index/ysbj.png"); */
  /*cursor: pointer;*/
  z-index: 2;
}

.saas_index_body .tenant_pro_cell_item_body_item:hover .textlineCss {
  background: #2A97E1;
}

.saas_index_body
.tenant_pro_cell_item_body_item:hover
.tenant_pro_cell_item_body_item_info {
  height: 0px;
  opacity: 0;
  z-index: 2;
}

.saas_index_body .tenant_pro_cell_item_body_item:hover .tenant_hove_show {
  height: 40px;
  opacity: 1;
  margin-bottom: 20px;
  z-index: 2;
}

.saas_index_body .tenant_pro_cell_item_body_item:hover .tenant_pro_cell_item_body_item_saleTag_img {
  display: none;
  z-index: 2;
}

.saas_index_body .title_banner_body {
  width: 100%;
  height: 100%;
  z-index: 2;
}

.saas_index_body .discount_body {
  width: 100%;
  position: absolute;
  top: 526px;
  left: 0px;
  /*background-color: #ffffff66;*/
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 110px;
  z-index: 12;
}

.saas_index_body .discount_body_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 450px;
  z-index: 11;
  height: 125px;
  margin: 0 20px;
  margin-top: 170px;
  background-color: #D2D2D2;
  color: #FFFFFF;
  background-color: rgba(218, 212, 212, 0.36);
  background-image: url("../assets/saasImg/index/banner@2x.png");

}

.saas_index_body .discount_body_item:hover {
  /*background-color: #ffffff66;*/
}

.saas_index_body .discount_body_item:hover .discount_body_item_top_title {
  /*color: #FFFFFF;*/
}

.saas_index_body .discount_body_item_top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.saas_index_body .discount_body_item_top_title {
  color: #000;
  font-size: 24px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  margin-right: 5px;
  color: #FFFFFF;
  margin-left: 10px;
}

.saas_index_body .discount_body_item_bottom {
  color: #666666;
  font-size: 16px;
  /*width: 60%;*/
  width: 85%;
  /*height: 40px;*/
  height: auto;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
  word-wrap: break-word;
  overflow: hidden;
}

.saas_index_body .tenant_pro_cell_item_body_item_saleTag {
  width: 50px;
  height: 50px;
  position: relative;
  /*top: 0;*/
  /*left: 0;*/
  top: 79%;
  left: 87%;
}

.saas_index_body .tenant_pro_cell_item_body_item_top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.saas_index_body .tenant_pro_cell_item_body_item_top_info {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  height: 140px;
  margin-right: 10px;
  margin-left: 10px;
}

.saas_index_body .tenant_pro_cell_item_body_item_top_proImg {
  width: 100px;
  height: 100px;
  margin-top: 30px;
}

.saas_index_body .cust_case {
  background: #fff;
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.saas_index_body .cust_title {
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  line-height: 30px;
  margin-bottom: 30px;
}

.saas_index_body .cust_body {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */
  width: 1200px;
  height: 360px;
}

.saas_index_body .img_array_body {
  display: flex;
  /* background-color: #0f68e8; */
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  color: #0f68e8;
  height: 100%;
  width: 1100px;
  margin-left: 50px;
}

.saas_index_body .img_array_body_item {
  width: 230px;
  height: 90px;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  border: 1px solid #ddd;
  padding: 20px 10px;
}

.saas_index_body .tenant_pro_cell_item_body_item_top:hover {
  cursor: pointer;
}

/*.saas_index_body .tenant_pro_cell_item_body_item_title:hover {*/
/*cursor: pointer;*/
/*}*/

.saas_index_body .tenant_pro_cell_item_body_item_name:hover {
  color: #2468F2;
  cursor: pointer;
}

.saas_index_body .tenant_pro_cell_item_body_item_remark:hover {
  color: #2468F2;
}

.saas_index_body .tenant_hove_show:hover {
  cursor: pointer;
}

.discount_body_item:hover {
  cursor: pointer;

}

.middle_menu {
  width: 100%;
  margin-top: 75px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.columnCss {
  width: 32%;
  /* background: #C8C8C8; */

}

.middle_menuList {
  width: 100%;
  /* height: 471px; */
  border: 2px solid #FFFFFF;
  box-shadow: 0px 2px 15px 0px rgba(21, 113, 179, 0.26);
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: -10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto !important;
}
</style>