<template>
  <div class="saas_detail_body">
    <div id="home" class="saas_detail_body_top_info">
      <div class="saas_detail_body_top_left_info">
        <div class="saas_detail_body_top_left_info_one">
          <div class="saas_detail_body_top_left_info_title_view">
            <div class="saas_detail_body_top_left_info_title_view_lab">{{ product.spName }}</div>
            <div v-if="product.typeValue == 2 && product.spCode != 'resource_bank'"
                 class="saas_detail_body_top_left_info_title_view_exp">
              <!-- 依赖 • {{product.relyName}}-->
              由第三方提供
            </div>
          </div>
          <div v-if="product.spRemark != null" class="saas_detail_body_top_left_info_remark">
            <div class="saas_detail_body_top_left_info_remark_one">{{ product.spRemark }}
              <span v-show="relyList.length > 0">（ 需同时订阅
                    <span v-for="(itemT, indexT) in relyList" :key="indexT">
                        <span class="product_info_rely" style="color:#589CFD;"
                              @click="goSup({ id: itemT.id, code: itemT.code })"> {{
                            itemT.name
                          }}</span> 
                      <span v-show="relyList.length > 1 && indexT < relyList.length - 1">或</span> 
                    </span>）
                </span>
              <span v-show="product.relyName != null && relyList.length == 0">（ 需同时订阅
                    <span>
                        <span class="product_info_rely"
                              style="color:#589CFD;"
                              @click="goSup({ id: product.id, code: product.spCode })"> {{
                            product.relyName
                          }}</span>
                    </span>）
                </span>
            </div>
          </div>
          <div v-show="product.statusValue == 10 && code != 'service_provider_sub'"
               class="saas_detail_body_top_left_info_price">
            订阅价格
            <div class="saas_detail_body_price_color">{{ product.unitPrice }}</div>
            {{ product.unitName }}
          </div>
          <div v-if="supProList.length > 0 && product.statusValue == 10"
               class="saas_detail_body_sup_product_view">
            <div class="saas_detail_body_sup_product_view_title">推荐搭配 ></div>
            <div class="saas_detail_body_sup_product_view_list">
              <div v-for="(item, index) in supProList" :key="index"
                   class="saas_detail_body_product_tab_item" @click="goSup(item)">{{ item.name }}
              </div>
            </div>
          </div>
          <div class="saas_detail_body_top_left_info_btnview">
            <!-- 新产品订阅 -->
            <div v-if="product.statusValue == 10"
                 :class="{ 'qr_code_button_top': !showProductQrCode }"
                 class="saas_detail_body_top_left_info_btnview_button"
                 @mouseleave="mouseLeave" @mouseover="mouseOver">
              <el-button-group v-if="experience">
                <el-button class="goto_try" round style="" @click="gotoTryAction()"> 申请试用</el-button>
                <el-button class="goto_subscribe" round type="primary" @click="gotoSubscribeAction()">
                  <span v-if="code == 'service_provider_sub'">前往入驻</span>
                  <span v-else>前往订阅</span>
                </el-button>
              </el-button-group>
              <el-button v-else round style="width: 133px; height: 37px;font-size: 22px;font-family: Source Han Sans SC;
                            font-weight: 400;background-color: #0050DA;
                            color: #FFFFFF; padding-bottom: 7px" type="primary" @click="gotoSubscribeAction()">
                <span v-if="code == 'service_provider_sub'">前往入驻</span>
                <span v-else>前往订阅</span>
              </el-button>

              <div v-show="showProductQrCode" class="develop_button_title ">请与产品负责人联系 ></div>
              <div v-show="showProductQrCode" class="develop_button_qr_code_min ">
                <img :src="qr_code.img" height="25" width="25"/>
              </div>
              <div v-show="showProductQrCode"
                   :class="{ 'develop_button_qr_code_max_mouseover': !showQrCode, 'develop_button_qr_code_max_mouseleave': showQrCode }"
                   class="develop_button_qr_code_max ">
                <img :src="qr_code.qr_img" height="100" width="100"/>
              </div>

            </div>
            <div v-else :class="{ 'qr_code_button_top': !showProductQrCode }"
                 class="saas_detail_body_top_left_info_btnview_button" @mouseleave="mouseLeave" @mouseover="mouseOver">
              <el-button class="develop_button" type="info"> {{ product.statusName }}</el-button>
              <div v-show="showProductQrCode" class="develop_button_title ">请与产品负责人联系 ></div>
              <div v-show="showProductQrCode" class="develop_button_qr_code_min ">
                <img :src="qr_code.img" height="25" width="25"/>
              </div>
              <div v-show="showProductQrCode"
                   :class="{ 'develop_button_qr_code_max_mouseover': !showQrCode, 'develop_button_qr_code_max_mouseleave': showQrCode }"
                   class="develop_button_qr_code_max ">
                <img :src="qr_code.qr_img" height="100" width="100"/>
              </div>
            </div>
            <!-- 已订阅的直接查看详情 -->
          </div>
        </div>
      </div>
      <div class="saas_detail_body_top_right_img">
        <img src="@/assets/saasImg/banner_saas.png" style="width:100%"/>
      </div>
      <div class="saas_detail_body_discount_body">
        <template>
          <div v-for="(item, index) in discountList" :key="index" class="saas_detail_body_discount_body_item"
               @click="goActive(index)">
            <div class="saas_detail_body_discount_body_item_top">
              <div class="saas_detail_body_discount_body_item_top_title">{{ item.title }}</div>
              <img v-if="item.img" :src="item.img" style="width: 23px; height: 29px"/>
            </div>
            <div class="saas_detail_body_discount_body_item_bottom">{{ item.msg }}</div>
          </div>
        </template>
      </div>
    </div>

    <!-- 展示入驻服务商信息 -->
    <div v-if="product.spCode == 'service_provider'" style="margin: 22px 38px">
      <el-row :gutter="28">
        <el-col v-for="(item, index) in providerList" :key="index" :span="6">
          <!-- <el-card style="margin-bottom:26px;height:260px;">
                        <el-row :gutter="20">
                            <el-col  :span="8">
                                <el-image style="width: 100px; height: 100px"  :src="item.logo" :fit="fit"></el-image>
                                <div style="text-align: left;">
                                    <span class="demonstration">{{ item.name }}</span>
                                </div>
                            </el-col>
                            <el-col  :span="16">
                               <span>{{item.intro}}</span>
                            </el-col>
                        </el-row>
                    </el-card> -->
          <el-card body-style="padding: 10px;" style="margin-bottom:26px;height:340px;">
            <div slot="header" class="clearfix" style="text-align: center;">
              <el-image :fit="fit" :src="item.logo_url" style="width: 100px; height: 100px">
                <div slot="error">
                  <img src="@/assets/saasImg/all_saas.png" style="width: 100px; height: 100px"/>
                </div>
              </el-image>
            </div>
            <div style="text-align: center;">
                <span style="color:#66666;font-size:16px;font-weight:bold">{{
                    item.carrier | ellipsisTitle
                  }}</span>
            </div>
            <div style="height:48px;text-align: center;padding-top:8px;padding-bottom:12px;">
              <el-tooltip :content="item.intro" effect="light" placement="bottom">
                <span style="font-size:14px;">{{ item.intro | ellipsisRemark }}</span>
              </el-tooltip>
            </div>
            <div style="text-align: center;margin-top: 26px;font-size:13px;">
                            <span v-if="item.capital">注册资金：<el-tag effect="plain" type="warning">{{ item.capital }}万
                                </el-tag></span>
              <el-divider v-if="item.capital && item.monthlyBilling" direction="vertical"></el-divider>
              <span v-if="item.monthlyBilling">开票规模：<el-tag effect="plain" type="warning">
                                    {{ item.monthlyBilling }}万/月</el-tag></span>
              <!-- <el-divider direction="vertical"></el-divider>
                            <span>草木深</span> -->
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <div id="saas_detail_body_product"
         :class="{ 'product_features_is_null': isFunctionData, 'product_features_is_not_null': !isFunctionData }"
         class="saas_detail_body_product">
      <div class="saas_detail_body_product_tab_title">
        <div class="saas_detail_body_product_tab_title_one"></div>
        <div class="saas_detail_body_product_tab_title_two"></div>
        <div class="saas_detail_body_product_tab_title_three">产品功能</div>
        <div class="saas_detail_body_product_tab_title_two"></div>
        <div class="saas_detail_body_product_tab_title_one"></div>
      </div>
      <div v-if="isFunctionData" style="font-size: 30px;color: #b1abab;margin: 50px auto">暂无描述</div>
      <div v-if="!isFunctionData" class="saas_detail_body_product_body">
        <div class="saas_detail_body_product_left_list">
          <div v-for="(item, index) in localData.productFeatures" :key="index"
               :class="{ 'saas_detail_body_featur_item_one': item.select, 'saas_detail_body_featur_item_t': !item.select }"
               class="saas_detail_body_featur_item"
               @click="createSelFeature(item)">
            {{ item.title }}
          </div>
        </div>
        <div class="saas_detail_body_product_right">
          <ul class="saas_detail_body_product_right_text">
            <li v-for="(item, index) in featurListOne.contentList"
                :key="index" class="saas_detail_body_product_right_content">
              <div class="saas_detail_body_product_right_point" style="display: inline"></div>
              <div class="saas_detail_body_product_right_content_text">{{ item.content }}</div>
            </li>
          </ul>
          <div v-show="featurImgNull" class="saas_detail_body_product_right_imgs">
            <el-carousel v-show="!featurImgs" :interval="5000" arrow="never" height="700px">
              <el-carousel-item v-for="(item, index) in featurImgList" :key="index">
                <img :src="item.img" class="saas_detail_body_product_right_img"/>
              </el-carousel-item>
            </el-carousel>
            <el-carousel v-show="featurImgs" :interval="5000" arrow="always" height="700px">
              <el-carousel-item v-for="(item, index) in featurImgList" :key="index">
                <img :src="item.img" class="saas_detail_body_product_right_img"/>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>

    <div id="saas_detail_body_proSup" class="saas_detail_body_pro_sup">
      <div class="saas_detail_body_product_tab_title" style="width: auto">
        <div class="saas_detail_body_product_tab_title_one"></div>
        <div class="saas_detail_body_product_tab_title_two"></div>
        <div class="saas_detail_body_product_tab_title_three">产品优势</div>
        <div class="saas_detail_body_product_tab_title_two"></div>
        <div class="saas_detail_body_product_tab_title_one"></div>
      </div>
      <div v-if="isAdvantageData" class="saas_detail_body_pro_sup_banner">
        <div class="saas_detail_body_pro_sup_banner_zero">
          <div v-for="(item, index) in localData.params" :key="index"
               class="saas_detail_body_pro_sup_banner_one">
            <div class="saas_detail_body_pro_sup_banner_img">
              <img :src="item.img" height="60px" width="60px"/>
            </div>
            <div class="saas_detail_body_pro_sup_banner_two">{{ item.title }}</div>
            <div class="saas_detail_body_pro_sup_banner_three">{{ item.msg }}</div>
          </div>
        </div>
      </div>
      <div v-if="!isAdvantageData" class="saas_detail_body_pro_sup_banner_null">暂无描述</div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import aEntityAPI from "@/api/sysAPI.js";
import {mapActions, mapGetters} from "vuex";
import {productCode} from "./saasProduct.js"

export default {

  props: ["saas"],

  components: {},

  computed: {
    ...mapGetters({
      sysConfig: "config/sysConfig"
    }),
    ...mapActions(["config/setConfig"])
  },

  data() {
    return {
      hrsaasImg: require("@/assets/hrsaas/index/hr_saas_logo.png"),
      saasImg: require("@/assets/saas_logo.png"),
      showQrCode: false,
      showProductQrCode: false,
      qr_code: {
        img: require("@/assets/qr/qr_code.png"),
        qr_img: null,
        xiekangfeng: require("@/assets/qr/xiekangfeng.png"),
        weiziling: require("@/assets/qr/weiziling.jpg"),
        xufang: require("@/assets/qr/xufang.jpg"),
        zhanglinlin: require("@/assets/qr/zhanglinlin.jpg"),
        zhaoyufeng: require("@/assets/qr/zhaoyufeng.jpg"),
      },
      featurImgNull: true,
      relyList: [],
      isLogin: false,
      experience: false,
      curId: null,
      code: '',
      spName: '',
      isFunctionData: false,
      isAdvantageData: false,
      title: '',
      mag: '',
      hostUrl: window.location.protocol + "//" + "localhost:8004",
      product: {},
      //推荐搭配数据
      supProList: [],
      discountList: [
        // {
        //     tag: 0,
        //     title: "免费试用",
        //     msg: "所有用户注册认证成功后工具类型产品可免费订阅试用30天",
        //     img: require("@/assets/saasImg/limit_hot.png"),
        // },
        {
          tag: 1,
          title: "满年8折",
          msg: "一次性订阅12月按8折收费",
          img: "",
        },
        {
          tag: 2,
          title: "满减钜惠",
          // msg: "2022年12月31日前，共生Saas在线服务产品单次订单满2万按照8折计费",
          msg: "单次订单满2万按照8折计费",
          img: "",
        },
        {
          tag: 3,
          title: "限时7折",
          // msg: "2022年9月30日之前，推广促销期间所有工具类型产品按照7折计费",
          msg: "工具类型产品按照7折计费",
          img: "",
        },
      ],
      featurList:
          [
            {
              "title": "运单管理",
              "select": true,
              "remark": "运单管理****************************************************************************",
              "imgList": [{
                "img": "/static/image/saasImg/space_img.png"
              }, {
                "img": "/static/image/banner1.png"
              }, {
                "img": "/static/image/saasImg/space_img.png"
              }]
            }, {
            "title": "轨迹管理",
            "select": false,
            "remark": "轨迹管理****************************************************************************",
            "imgList": [{
              "img": "/static/image/saasImg/space_img.png"
            }, {
              "img": "/static/image/banner1.png"
            }, {
              "img": "/static/image/saasImg/space_img.png"
            }]
          }, {
            "title": "全覆盖移动端",
            "select": false,
            "remark": "全覆盖移动端****************************************************************************",
            "imgList": [{
              "img": "/static/image/saasImg/space_img.png"
            }, {
              "img": "/static/image/banner1.png"
            }, {
              "img": "/static/image/saasImg/space_img.png"
            }]
          }, {
            "title": "智能数据上报",
            "select": false,
            "remark": "智能数据上报****************************************************************************",
            "imgList": [{
              "img": "/static/image/saasImg/space_img.png"
            }, {
              "img": "/static/image/banner1.png"
            }, {
              "img": "/static/image/saasImg/space_img.png"
            }]
          }],
      featurImgList: [],
      selFeature: {},
      productSupList: [],
      featurListOne: '',
      isFlag: false,
      productAdvantageList: [],
      localData: {},
      //推荐搭配数据
      productCode:[],
      featurImgs: false
      // 服务商列表
      , providerList: [
        {
          logo: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.16pic.com%2F00%2F01%2F40%2F16pic_140904_b.jpg&refer=http%3A%2F%2Fpic1.16pic.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1664433327&t=16280fd80ad0b9ce59a5a978b25b6879'
          ,
          name: '安徽网货服务商'
          ,
          intro: '公司是一家为企业提供信息化出行解决方案的差旅公司，通过员工出行大数据分析，优化企业差旅出行管理，随时随地为企业提供机票月结、机票代订、国内外机票，团购机票、酒店、专车、机场贵宾厅等票务代理全方位便捷服务。通过提供专业的企业商旅信息化解决方案，提供差旅系统的服务，企业大客户集中采购等资源整合，节约现金流和企业经营成本，实现资源合理利用'
          ,
          scale: 5000
          ,
          capital: 100
        },
        {
          logo: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.redocn.com%2Fsheji%2F20141117%2FzimuYlogosheji2_3239039_small.jpg&refer=http%3A%2F%2Fimg.redocn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1664433374&t=f844aa3ad5b7a6d4f7d6acb97ee106ca'
          ,
          name: '四川网货服务商'
          ,
          intro: '公司是一家为企业提供信息化出行解决方案的差旅公司，通过员工出行大数据分析，优化企业差旅出行管理，随时随地为企业提供机票月结、机票代订、国内外机票，团购机票、酒店、专车、机场贵宾厅等票务代理全方位便捷服务。通过提供专业的企业商旅信息化解决方案，提供差旅系统的服务，企业大客户集中采购等资源整合，节约现金流和企业经营成本，实现资源合理利用'
          ,
          scale: 5000
          ,
          capital: 100
        },
        {
          logo: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Ffile02.16sucai.com%2Fd%2Ffile%2F2015%2F0406%2F9264435d03caabce5cab77ca92b5caae.jpg&refer=http%3A%2F%2Ffile02.16sucai.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1664433455&t=820b50e052f3d86be18ed926eeac3f3e'
          ,
          name: '湖南网货服务商'
          ,
          intro: '公司是一家为企业提供信息化出行解决方案的差旅公司，通过员工出行大数据分析，优化企业差旅出行管理，随时随地为企业提供机票月结、机票代订、国内外机票，团购机票、酒店、专车、机场贵宾厅等票务代理全方位便捷服务。通过提供专业的企业商旅信息化解决方案，提供差旅系统的服务，企业大客户集中采购等资源整合，节约现金流和企业经营成本，实现资源合理利用'
          ,
          scale: 5000
          ,
          capital: 100
        },
        {
          logo: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.redocn.com%2Fsheji%2F20160526%2FbianxingdaqifangdichanZzimulogo_6383209_small.jpg&refer=http%3A%2F%2Fimg.redocn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1664433486&t=c1e833e051098f0db32fcff880dd01b1'
          ,
          name: '山东网货服务商'
          ,
          intro: '公司是一家为企业提供信息化出行解决方案的差旅公司，通过员工出行大数据分析，优化企业差旅出行管理，随时随地为企业提供机票月结、机票代订、国内外机票，团购机票、酒店、专车、机场贵宾厅等票务代理全方位便捷服务。通过提供专业的企业商旅信息化解决方案，提供差旅系统的服务，企业大客户集中采购等资源整合，节约现金流和企业经营成本，实现资源合理利用'
          ,
          scale: 5000
          ,
          capital: 100
        },
        {
          logo: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.redocn.com%2Fsheji%2F20160526%2FbianxingdaqifangdichanZzimulogo_6383209_small.jpg&refer=http%3A%2F%2Fimg.redocn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1664433486&t=c1e833e051098f0db32fcff880dd01b1'
          ,
          name: '山东网货服务商'
          ,
          intro: '公司是一家为企业提供信息化出行解决方案的差旅公司，通过员工出行大数据分析，优化企业差旅出行管理，随时随地为企业提供机票月结、机票代订、国内外机票，团购机票、酒店、专车、机场贵宾厅等票务代理全方位便捷服务。通过提供专业的企业商旅信息化解决方案，提供差旅系统的服务，企业大客户集中采购等资源整合，节约现金流和企业经营成本，实现资源合理利用'
          ,
          scale: 5000
          ,
          capital: 100
        },
        {
          logo: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.redocn.com%2Fsheji%2F20160526%2FbianxingdaqifangdichanZzimulogo_6383209_small.jpg&refer=http%3A%2F%2Fimg.redocn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1664433486&t=c1e833e051098f0db32fcff880dd01b1'
          ,
          name: '山东网货服务商'
          ,
          intro: '公司是一家为企业提供信息化出行解决方案的差旅公司，通过员工出行大数据分析，优化企业差旅出行管理，随时随地为企业提供机票月结、机票代订、国内外机票，团购机票、酒店、专车、机场贵宾厅等票务代理全方位便捷服务。通过提供专业的企业商旅信息化解决方案，提供差旅系统的服务，企业大客户集中采购等资源整合，节约现金流和企业经营成本，实现资源合理利用'
          ,
          scale: 5000
          ,
          capital: 100
        }
      ]
    };
  },

  watch: {
    sysConfig: function (nval, oval) {
      if (nval && nval.logoFile && nval.logoFile.filePath) {
        var link = document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";

        var serverHost = window.location.host;
        if (serverHost.indexOf("hrsaas") > -1 || serverHost.indexOf("saas.gz-vip.com") > -1) {
          link.href = this.hrsaasImg;
        } else if (serverHost.indexOf("saas") > -1) {
          link.href = this.saasImg;
        } else {
          link.href = this.__ftpPath + nval.logoFile.filePath;
        }
        document.getElementsByTagName("head")[0].appendChild(link);
      }
    }
  },


  created() {

    this["config/setConfig"];

    this.productCode = productCode;

    if (this.$route.params && this.$route.params.spId != null) {
      this.curId = this.$route.params.spId;
    }

    if (this.$route.params && this.$route.query.code != null) {
      this.code = this.$route.query.code;
      if (this.code == 'resource_dri_levy' ||
          this.code == 'resource_bank' ||
          this.code == 'resource_signature' ||
          this.code == 'resource_etc_ticket' ||
          this.code == 'resource_oil_card' ||
          this.code == 'resource_insurance'
      ) {
        this.showProductQrCode = true;
      }
    }

    if (this.$route.params && this.$route.query.spName != null) {
      this.spName = this.$route.query.spName;
    }

    //产品详情
    this.getSaasProDetail();

  },

  mounted() {

    this.getProductAdvantageList(this.code);

    //推荐搭配
    this.getsupProList(this.code);

    //判断产品功能是否为空
    if (this.localData == null || this.localData.productFeatures == 0) {
      console.log("数据为空·")
      this.isFunctionData = true
    }

    if (this.localData != null && this.localData.params.length > 0) {
      this.isAdvantageData = true
    }

    // //产品详情
    // this.getSaasProDetail();

    //判断是否登录状态
    this.verificationLogin();

    //初始化 产品功能
    this.initProductFeatures();

    // 服务商列表
    this.listProvider();

  },

  methods: {
    //进入
    mouseOver() {
      this.showQrCode = true;
    },

    //移除
    mouseLeave() {
      this.showQrCode = false;
    },

    //初始化 产品功能
    initProductFeatures() {
      if (!this.isFunctionData) {
        this.featurListOne = this.localData.productFeatures[0];
        if (this.featurListOne.imgs != null) {
          this.featurImgList = this.featurListOne.imgs;
          if (this.featurImgList.length == 1) {
            this.featurImgs = false
          }
          if (this.featurImgList.length > 1) {
            this.featurImgs = true
          }
        }
      }
    },


    /**
     * 查询服务商列表
     */
    listProvider() {
      if (this.code == 'service_provider') {
        Vue.http.post("provider/listProvider", {}).then((res) => {
          if (res.data.success) {
            this.providerList = res.data.data;
          }
        });
      }
    },

    //前往试用订阅页面
    gotoSubscribeAction() {
      var url = null;
      if (this.isLogin) {
        //saas服务商订阅界面
        // if(this.code == "service_provider_sub"){
        //     url = window.location.protocol + "//" + window.location.host + "/saas56.html#/subServiceProviderV2";
        // }else{
        url = window.location.protocol + "//" + window.location.host + "/saas56.html#/saasSubscribeTab/" + this.curId + "/" + this.code;
        // }
        window.location.href = url
      } else {
        sessionStorage.setItem("beforeLoginUrl", "/saas56.html#/saasSubscribeTab/" + this.curId + "/" + this.code);
        url = window.location.protocol + "//" + window.location.host + "/login.html#/login/saas?fromUrl=" + encodeURIComponent(window.location.href);
        window.location.href = url
      }
    },

    goActive(index) {

      if (index == 0) {
        this.$router.push({
          name: "saasActive", params: {
            index: index
          }
        });
      }
      if (index == 1) {
        this.$router.push({
          name: "saasActive", params: {
            index: index
          }
        });
      }
      if (index == 2) {
        this.$router.push({
          name: "saasActive", params: {
            index: index
          }
        });
      }
    },

    //前往试用订阅页面
    gotoTryAction() {
      var url = null;
      if (this.isLogin) {
        url = window.location.protocol + "//" + window.location.host + "/saas56.html#/saasSubscribeTabTry/" + this.curId + "/" + this.code;
        window.open(url)
      } else {
        sessionStorage.setItem("beforeLoginUrl", "/saas56.html#/saasSubscribeTabTry/" + this.curId + "/" + this.code);
        url = window.location.protocol + "//" + window.location.host + "/login.html#/login/saas?fromUrl=" + encodeURIComponent(window.location.href);
        window.location.href = url
      }
    },

    //判断是否登录状态
    verificationLogin() {
      aEntityAPI
          .saasRequest("UserProductSubscribeService", "saasIndexConfig")
          .then((res) => {
            if (res.data.success && res.data.data.user != null) {
              this.isLogin = true
            }
          });
    },

    // 产品功能-优势
    getProductAdvantageList(priductCode) {
      var products = {
        //在线调车
        "tool_online_wlhy": {
          //产品功能
          productFeatures: [
            {
              title: "运单发布",
              contentList: [
                {
                  content: "系统支持托运人可以在录入、Excel导入和OpenAPI接口等多种方式录入运单，并且托运人可以在PC端和微信小程序进行便捷得业务管理。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/zxdc_1.png"),
                },
              ]
            },
            {
              title: "车货匹配",
              contentList: [
                {
                  content: "平台会针对整车运力进行画像，针对车型、线路、行驶状态等多维度进行特征采集，为发货运单匹配到合适的车辆。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/zxdc_2.png"),
                },
              ]
            },
            {
              title: "线上成交",
              contentList: [
                {
                  content: "平台具备完善的交易流程。支持竞价、抢单和轮候三种交易方式，在线签署运输协议，并支持付款阶段、费用类型、支付方式等多维度管理运输费用。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/zxdc_3.png"),
                },
              ]
            },
            {
              title: "自定义可配置",
              contentList: [
                {
                  content: "拥有可视化的配置中心，各项系统参数、资源和接口可以快速配置使用，系统上线快。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/zxdc_4.png"),
                },
              ]
            },
            /*   {
                               title: "金融支付",
                               contentList: [
                                   {
                                       content: "针对车货匹配交易中的应收托运人费用及应付车辆费用，支持付款阶段、费用类型、支付方式等多维度管理，支持交易费用在线变更，更好得支撑支付流程和金融业务的开展。",
                                   },
                               ],
                               "select": false,
                               imgs: [
                                   {
                                       img: require("@/assets/saasImg/xsdc_4.png"),
                                   },
                               ]
                           },*/
            /*  {
                              title: "咨询投诉",
                              contentList: [
                                  {
                                      content: "用户可以随时进行业务咨询和针对交易发起投诉。平台客服可在线监控到用户注册、身份变更、运单交易、支付异常全过程监控，给用户提供满意的服务。",
                                  },
                              ],
                              "select": false,
                              imgs: [
                                  {
                                      img: require("@/assets/saasImg/xsdc_5.png"),
                                  },
                              ]
                          },
                          {
                              title: "在线评价",
                              contentList: [
                                  {
                                      content: "平台拥有全面的信用体系,从履约时效、过程安全和服务态度等多维度全面量化单次交易评分，并针对不断累计的交易评分实现从1到5星的信用评级，让平台交易更高效安全。",
                                  },
                              ],
                              "select": false,
                              imgs: [
                                  {
                                      img: require("@/assets/saasImg/xsdc_6.png"),
                                  },
                              ]
                          },
                          {
                              title: "查询统计",
                              contentList: [
                                  {
                                      content: "系统将用户信息、运单交易、车辆轨迹、支付账单、电子回单和发票信息实现了全链整合，支持按发票号的全链数据反查，满足平台监管要求。",
                                  },
                              ],
                              "select": false,
                              imgs: [
                                  {
                                      img: require("@/assets/saasImg/xsdc_7.png"),
                                  },
                              ]
                          },
                          {
                              title: "数据调取",
                              contentList: [
                                  {
                                      content: "平台提供独立的省平台对接模块，实现数据的前中后全过程管理，包含：上报前的预校验，数据一键上报和上报后的异常处理，满足交通运输的数据监管要求。同时也有专门模块，供税局进行数据调取。",
                                  },
                              ],
                              "select": false,
                              imgs: [
                                  {
                                      img: require("@/assets/saasImg/xsdc_8.png"),
                                  },
                              ]
                          },*/

          ],
          //产品优势
          params: [
            {
              img: require("@/assets/saasImg/features/move.png"),
              title: '移动',
              msg: '平台为发货人、司机、车网代都配备了移动端App或小程序，支持随时随地进行在线工作。',
            },
            {
              img: require("@/assets/saasImg/features/real_ime.png"),
              title: '实时',
              msg: '系统通过移动化、数据采集、信息和工单推送，实现了人员实时在线，任务实时反馈，业务实时处理。',
            },
            {
              img: require("@/assets/saasImg/features/convenient.png"),
              title: '便捷',
              msg: '系统上线快速便捷，多租户架构即开即用，可视化配置中心支持快速自定义配置。',
            },
            /* {
                             img: require("@/assets/saasImg/gouxianjin@2x.png"),
                             title: '够先进',
                             msg: '多种车货匹配交易模式的支持，全程透明化。',
                         },*/
          ],
        },
        //在线结算
        "tool_online_pay": {
          //产品功能
          productFeatures: [
            {
              title: "自动收款",
              contentList: [
                {
                  content: "读取到银行的收款流水，通过收付双方账号和汇款识别码，匹配到公对公汇款单，实现自动认款核销，完成收款。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/xsjs_1.png"),
                },
              ]
            },
            {
              title: "批量付款",
              contentList: [
                {
                  content: "平台会管理司机会员的银行账号，针对系统达到付款要求中的应付运费，实现公对私（平台对司机）的批量自动付款。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/xsjs_2.png"),
                },
              ]
            },
            {
              title: "异常报告",
              contentList: [
                {
                  content: "针对批量支付中，付款失败或被退回的账单，能读取支付信息后，主动报告给平台，便于平台客服人工介入处理。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/xsjs_3.png"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            {
              img: require("@/assets/saasImg/features/efficient.png"),
              title: '高效',
              msg: '通过银企直联，实时在线自动收款和批量支付，极大提高工作效率。',
            },
            {
              img: require("@/assets/saasImg/features/easy.png"),
              title: '易用',
              msg: '已对接农行、交行、光大、平安、、民生、兴业、江苏、徽商等银行，还包含易宝三方支持，用户可任选其中已有银行实现快速上线。',
            },
            {
              img: require("@/assets/saasImg/features/security.png"),
              title: '安全',
              msg: '业务和财务数据一体化实现数据双向校验，并结合平台多层安全防护，保障资金支付安全性。',
            },
          ],
        },
        //在线质控
        "tool_online_tracking": {
          //产品功能
          productFeatures: [
            {
              title: "运输全程监控",
              contentList: [
                {
                  content: "通过大数据分析车辆实时位置和轨迹数据，实现运单提货、在途和到达的状态自动流转，以及过程中的超时停车、轨迹偏离等异常信息的发现。\n" +
                      "基于手机app、北斗、基站 多种方式进行车辆实时定位，还可以通过北斗查询 到车辆的历史轨迹，实现实时追踪，全程监控。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/zxzk_1.png"),
                },
              ]
            },
            {
              title: "异常闭环管理",
              contentList: [
                {
                  content: "通过大数据分析车辆轨迹，发现在途和到达的状态自动流转，以及过程中的超时停车、轨迹偏离等异常，并自动预警自动产生工单，达到管理闭环，提升服务质量。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/zxgz_2.png"),
                },
              ]
            },
            {
              title: "符合监管要求",
              contentList: [
                {
                  content: "系统将用户信息、运单交易、车辆轨迹、支付账单、电子回单和发票信息实现了全链整合，支持按发票号的全链数据反查，满足平台监管要求。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/zxzk_3.png"),
                },
              ]
            },
            {
              title: "平台诚信体系",
              contentList: [
                {
                  content: "平台拥有全面的信用体系从、履约时效、过程安全和服务态度等多维度全面量化单次交易评分，并针对不断累计的交易评分实现从1到5星的信用评级，让平台交易更高效安全。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/zxzk_4.png"),
                },
              ]
            },
            {
              title: "支持咨询投诉",
              contentList: [
                {
                  content: "用户可以随时进行业务咨询和针对交易发起投诉。平台客服可从在线监控到用户注册、身份变更、运单交易、支付异常全过程监控，给用户提供满意的服务。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/zxzk_5.png"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            {
              img: require("@/assets/saasImg/features/real_ime.png"),
              title: '实时',
              msg: '通过移动终端、北斗和基站，针对车辆进行实时定位和轨迹分析。',
            },
            {
              img: require("@/assets/saasImg/features/precise.png"),
              title: '精准',
              msg: '并根据车辆位置和轨迹数据，驱动运单的提货、在途和卸货状态，精准反应业务的实时状况并发现业务异常。',
            },
            {
              img: require("@/assets/saasImg/features/early_warning.png"),
              title: '预警',
              msg: '系统针对异常进行分级管理，异常预警自动产生工单并提醒给系统工作人员，实现业务链的工作协同。',
            },
          ],
        },
        //订单管理
        "tool_order": {
          //产品功能
          productFeatures: [
            {
              title: "快速下单",
              contentList: [
                {
                  content: "支持OpenApi接口、Excel批量导入和手工多种方式录单，同时具备地址信息等记忆功能，并支持小程序拍照快速录单。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/ddgl_1.png"),
                },
              ]
            },
            {
              title: "强大物流计划",
              contentList: [
                {
                  content: "支持订单拆分、中转分段和智能拼载，还支持线路规划，录单同时就可以安排物流计划，支持下发给承运商及车辆。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/ddgl_2.png"),
                },
              ]
            },
            {
              title: "运作过程跟踪",
              contentList: [
                {
                  content: "系统会跟踪订单的计划、发运、签收等节点信息,还可关联在线派单和在线调车模块,实现调车、在途全过程透明化。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/ddgl_3.png"),
                },
              ]
            },
            {
              title: "在线签收",
              contentList: [
                {
                  content: "支持订单按货物数量、吨位、体积完成签收，可在线登记货物的漏发、破损等异常，并进行可进行退货、补发等处理。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/ddgl_4.png"),
                },
              ]
            },

          ],
          //产品优势
          params: [
            {
              img: require("@/assets/saasImg/features/move.png"),
              title: '移动',
              msg: '平台为发货人、承运商、司机都配备了微信小程序，支持随时随地进行在线工作。',
            },
            {
              img: require("@/assets/saasImg/features/intelligent.png"),
              title: '智能',
              msg: '可以根据订单线路、车型的满载率自动生成物流计划，结合承运商合同实现自动派单。',
            },
            {
              img: require("@/assets/saasImg/features/practical.png"),
              title: '实用',
              msg: '将订单拆分、中转、计划发运到承运商和车辆，整合到一起，功能简单使用。',
            },
          ],
        },
        //结算管理
        "tool_tms_settle": {
          //产品功能
          productFeatures: [
            {
              title: "应收管理",
              contentList: [
                {
                  content: "可按客户、费用类型、支付方式等维度维护应收费用，并可以对接合同智能计费产品，完成快速预估。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/jsgl_1.png"),
                },
              ]
            },
            {
              title: "应付管理",
              contentList: [
                {
                  content: "支持按账期、类型和支付方式维护费用，并可以对接合同智能计费产品，完成快速预估。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/jsgl_2.png"),
                },
              ]
            },
            {
              title: "盈利报表",
              contentList: [
                {
                  content: "支持按公司整体、客户订单和运单等维度，分析业务的盈利情况。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/jsgl_3.png"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            {
              img: require("@/assets/saasImg/features/move.png"),
              title: '移动',
              msg: '平台为发货人、承运商、司机都配备了微信小程序，支持随时随地进行在线工作。',
            },
            {
              img: require("@/assets/saasImg/features/practical.png"),
              title: '实用',
              msg: '支持类型、付款阶段和支付方式等多维度的费用管理，具备完整的应付和应收流程。',
            },
            {
              img: require("@/assets/saasImg/features/collaborate.png"),
              title: '协同',
              msg: '支持承运商应付费用在线对账，费用变更在线确认。并支持对接在线调车，完成整车运输的在线交易。',
            },
          ],
        },
        //在线派单
        "tool_online_dispatch": {
          //产品功能
          productFeatures: [
            {
              title: "承运商登录上线",
              contentList: [
                {
                  content: "支持给承运商开通系统账号，其登录后可以在线接单。同时承运商也可以使用微信小程序在线协作。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/zxpc_1.png"),
                },
              ]
            },
            {
              title: "承运商在线协作",
              contentList: [
                {
                  content: "承运商上线后，可以上报提货车辆、上传电子回单和在线对账，提高工作效率，实现运作过程透明化。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/zxpc_2.png"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            {
              img: require("@/assets/saasImg/features/move.png"),
              title: '移动',
              msg: '平台为发货人、承运商、司机都配备了微信小程序，支持随时随地进行在线工作。',
            },
            {
              img: require("@/assets/saasImg/features/security.png"),
              title: '可靠',
              msg: '承运商接单、调车、跟踪和回单，都可以实时在线完成，业务过程管理可靠。',
            },
            {
              img: require("@/assets/saasImg/features/efficient.png"),
              title: '及时',
              msg: '可以通过系统，实现发货人、承运商和司机之间业务指令及时传达与作业反馈。',
            },
          ],
        },
        // 在线投保
        "insurance_service_consumer": {
          //产品功能
          //产品功能
          productFeatures: [
            {
              title: "优选保险承运商",
              contentList: [
                {
                  content: "可以接入多家保险承运商，支持按线路、按项目配置不同的保险服务商。投保、支付、开票、理赔全部在线化处理，方便便捷。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/hwysbx_1.png"),
                },
              ]
            },
            {
              title: "最优化参保方式",
              contentList: [
                {
                  content: "支持按货值、按运费等多种参保方式，针对货量、距离等综合考量优先选择最合适的保险承运商。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/hwysbx_2.png"),
                },
              ]
            },
          ],

          //产品优势
          params: [
            {
              img: require("@/assets/saasImg/features/real_ime.png"),
              title: '灵活',
              msg: '通过对接多家优质保险服务商，为您提供多种保险服务，综合险、基本险可灵活配置和选择。',
            },
            {
              img: require("@/assets/saasImg/features/move.png"),
              title: '可靠',
              msg: '保险服务商都经过平台甄选，服务可靠、性价比高',
            },
            {
              img: require("@/assets/saasImg/features/convenient.png"),
              title: '便捷',
              msg: '一键在线投保，通过EDI接口和保险公司无缝化衔接，操作便捷。',
            },
          ],
        },
        //承运商管理
        "tool_supplier": {
          //产品功能
          productFeatures: [
            // {
            //     title: "运单管理",
            //     contentList: [
            //         {
            //             content: "运单管理**********************************************************************",
            //         },
            //     ],
            //     "select": true,
            //     imgs: [
            //         {
            //             img: require("@/assets/saasImg/online_shunting_1.png"),
            //         },
            //         {
            //             img: require("@/assets/saasImg/online_shunting_2.png"),
            //         },
            //     ]
            // },
          ],
          //产品优势
          params: [
            // {
            //     img: require("@/assets/saasImg/mianfei@2x.png"),
            //     title: '免费试用',
            //     msg: '支持免费试用，满意后再订阅。',
            // },
          ],
        },
        //零担发运
        "tool_less_shipped": {
          //产品功能
          productFeatures: [
            // {
            //     title: "运单管理",
            //     contentList: [
            //         {
            //             content: "运单管理**********************************************************************",
            //         },
            //     ],
            //     "select": true,
            //     imgs: [
            //         {
            //             img: require("@/assets/saasImg/online_shunting_1.png"),
            //         },
            //         {
            //             img: require("@/assets/saasImg/online_shunting_2.png"),
            //         },
            //     ]
            // },
          ],
          //产品优势
          params: [
            // {
            //     img: require("@/assets/saasImg/mianfei@2x.png"),
            //     title: '免费试用',
            //     msg: '支持免费试用，满意后再订阅。',
            // },
          ],
        },
        //合同智能计费
        "tool_contract_price": {
          //产品功能
          productFeatures: [
            {
              title: "可配置智能计费",
              contentList: [
                {
                  content: "通过四步配置计费规则、维护价格数据、智能引擎计算和存储预估结果来完成智能计费，将员工从繁琐计费工作中释放出来。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/htznjf_1.png"),
                },
              ]
            },
            {
              title: "智能快速预估",
              contentList: [
                {
                  content: "订单保存同时，即可以根据客户和承运商信息快速预估出应付和应收的费用信息。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/htznjf_2.png"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            {
              img: require("@/assets/saasImg/features/intelligent.png"),
              title: '智能',
              msg: '自研发的强大的智能价格表，支持市面上绝大多数的运输业务计价表格。',
            },
            {
              img: require("@/assets/saasImg/features/real_ime.png"),
              title: '实时',
              msg: '在订单保存和变更后，系统会根据价格表立刻预估出来收入和成本费用。',
            },
            {
              img: require("@/assets/saasImg/features/collaborate.png"),
              title: '协同',
              msg: '在物流计划中根据价格表自动推荐合适的承运商，与应收应付模块无缝对接。',
            },
          ],
        },
        //开票管理
        "tool_blling": {
          //产品功能
          productFeatures: [
            {
              title: "开票管理",
              contentList: [
                {
                  content: "支持对对账后收入，进行开票管理。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/kpgl_1.png"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            // {
            //     img: require("@/assets/saasImg/mianfei@2x.png"),
            //     title: '免费试用',
            //     msg: '支持免费试用，满意后再订阅。',
            // },
          ],
        },
        //自有车管理
        "tool_owned_car": {
          //产品功能
          productFeatures: [
            // {
            //     title: "运单管理",
            //     contentList: [
            //         {
            //             content: "运单管理**********************************************************************",
            //         },
            //     ],
            //     "select": true,
            //     imgs: [
            //         {
            //             img: require("@/assets/saasImg/online_shunting_1.png"),
            //         },
            //         {
            //             img: require("@/assets/saasImg/online_shunting_2.png"),
            //         },
            //     ]
            // },
          ],
          //产品优势
          params: [
            // {
            //     img: require("@/assets/saasImg/mianfei@2x.png"),
            //     title: '免费试用',
            //     msg: '支持免费试用，满意后再订阅。',
            // },
          ],
        },
        //大批量发运
        "tool_bulk_freight": {
          //产品功能
          productFeatures: [
            {
              title: "大批量发运",
              contentList: [
                {
                  content: "支持货源二维码的方式，司机可以扫码先发车，再上传磅单用于结算。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/dplfy.png"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            {
              img: require("@/assets/saasImg/features/efficient.png"),
              title: '高效',
              msg: '支持货主大批量货物发单，司机扫码上传磅单，完成循环运输。',
            },
            {
              img: require("@/assets/saasImg/features/practical.png"),
              title: '实用',
              msg: '系统支持货物的毛重和净重、货损控制和批量运费结算管理，很好支持了大批量货物的运输业务。',
            },
            {
              img: require("@/assets/saasImg/features/collaborate.png"),
              title: '协同',
              msg: 'API标准化接口，智能终端无缝链接客户IT系统，实现物流交付高效协同管理。',
            },
          ],
        },
        //数据可视化
        "tool_big_data": {
          //产品功能
          productFeatures: [
            {
              title: "模块内容配置",
              contentList: [
                {
                  content: "针对LED屏的组合分割，支持各个模块选择不同的内容，进行组合配置。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/big_data_1.png"),
                },
              ]
            },
            {
              title: "大屏幕组合展示",
              contentList: [
                {
                  content: "各模块内容可以组合为一个大屏投放到LED屏幕上进行展示。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/big_data_2.png"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            {
              img: require("@/assets/saasImg/features/configurable.png"),
              title: '可配置',
              msg: '拥有内容模块、展示参数、离线数据维护等配置，帮助您快速构建可视化内容。',
            },
            {
              img: require("@/assets/saasImg/features/easy.png"),
              title: '易使用',
              msg: '通过可视化的内容模板和布局和简单的配置，可实现多屏组合展示。',
            },
            {
              img: require("@/assets/saasImg/features/beautiful.png"),
              title: '美观',
              msg: '丰富的图表类型，流畅的交互体验。',
            },
          ],
        },
        //油卡（实体/电子）
        "resource_oil_card": {
          //产品功能
          productFeatures: [
            {
              title: "灵活的油卡支持",
              contentList: [
                {
                  content: "支持中石油、中石化等实体主副卡的油卡管理,同时也支持能链团油的三方油站。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/yk_1.png"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            // {
            //     img: require("@/assets/saasImg/mianfei@2x.png"),
            //     title: '免费试用',
            //     msg: '支持免费试用，满意后再订阅。',
            // },
          ],
        },
        //货物运输保险
        "resource_insurance": {
          //产品功能
          productFeatures: [
            {
              title: "优选保险承运商",
              contentList: [
                {
                  content: "可以接入多家保险承运商，并配置不同的免赔额，费率等信息。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/hwysbx_1.png"),
                },
              ]
            },
            {
              title: "最优化参保方式",
              contentList: [
                {
                  content: "支持按货值、按运费等多种参保方式，针对货量、距离等综合考量优先选择最合适的保险承运商。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/hwysbx_2.png"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            // {
            //     img: require("@/assets/saasImg/mianfei@2x.png"),
            //     title: '免费试用',
            //     msg: '支持免费试用，满意后再订阅。',
            // },
          ],
        },
        //通行费票据
        "resource_etc_ticket": {
          //产品功能
          productFeatures: [
            {
              title: "自动推单申请发票",
              contentList: [
                {
                  content: "按系统中的运单自动推送到ETC发票，系统自动化操作。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/zdtsydsq.png"),
                },
              ]
            },
            {
              title: "在线索取ETC发票",
              contentList: [
                {
                  content: "推送合格的运单，会自动索取ETC进项票，快速可靠。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/zxsqfp.png"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            {
              img: require("@/assets/saasImg/features/easy.png"),
              title: '易用',
              msg: '通过系统对接，完成业务在线操作，减少繁琐操作。',
            },
          ],
        },
        //电子签章（E签宝）
        "resource_signature": {
          //产品功能
          productFeatures: [
            {
              title: "生物识别安全注册",
              contentList: [
                {
                  content: "新用户可通过人脸识别等生物特征认证授权后，才可以进行协议签署。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/yhzc.png"),
                }
              ]
            },
            {
              title: "在线完成协议签署",
              contentList: [
                {
                  content: "通过系统在线完成合同签署，并实现电子签章的存单。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/xybs.png"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            {
              img: require("@/assets/saasImg/anquan@2x.png"),
              title: '安全性',
              msg: '电子签名，防篡改防假冒，可靠合规',
            },
          ],
        },
        //银企云托管
        "resource_bank": {
          //产品功能
          productFeatures: [
            {
              title: "可靠的云服务器环境",
              contentList: [
                {
                  content: "天翼私有云机房，符合7*24可用的安全规范，服务器硬件和网络等环境安全可靠。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/yqytg_1.png"),
                },
              ]
            },
            {
              title: "支付安全有保障",
              contentList: [
                {
                  content: "银企云的UKey或证书均可即插即用，所有安全密码均由用户管理和远程录入，数据加密且安全。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/yqytg_2.png"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            {
              img: require("@/assets/saasImg/features/security.png"),
              title: '安全',
              msg: '独立天翼云机房部署，多层安全防护，保障前置机安全',
            },
            {
              img: require("@/assets/saasImg/features/stablize.png"),
              title: '稳定',
              msg: '天翼云机房环境稳定，可实现7*24稳定工作',
            },
            {
              img: require("@/assets/saasImg/features/peace_mind.png"),
              title: '省心',
              msg: '云端部署，相当于用户自已部署，操作简单且价格实惠',
            },
          ],
        },
        //司机代征
        "resource_dri_levy": {
          //产品功能
          productFeatures: [
            {
              title: "按需推送代征运单",
              contentList: [
                {
                  content: "在系统接口配置开通代征服务后，在系统中选择所需代征的交易，一键发送代征需求。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/sjdz_1.png"),
                },
              ]
            },
            {
              title: "自动同步发票状态",
              contentList: [
                {
                  content: "针对推送成功的运单，服务商将会进行数据审核，审核通过后悔开具发票，系统会自动获取到运单的审核和开票状态。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/sjdz_2.png"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            {
              img: require("@/assets/saasImg/features/easy.png"),
              title: '易用',
              msg: '通过系统对接，完成业务在线操作，减少繁琐操作。',
            },
          ],
        },
        //北斗坐标
        "data_,dingwei,guiji,trace_forSaas": {
          //产品功能
          productFeatures: [
            {
              title: "数据云服务即开即用",
              contentList: [
                {
                  content: "通过OpenApi可在线进行车辆实时定位，批量历史轨迹查询，完成坐标信息获取。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/bdzb.png"),
                },
              ]
            },

          ],
          //产品优势
          params: [
            // {
            //     img: require("@/assets/saasImg/mianfei@2x.png"),
            //     title: '免费试用',
            //     msg: '支持免费试用，满意后再订阅。',
            // },
          ],
        },
        //短信服务
        "data_,send_sms": {
          //产品功能
          productFeatures: [
            {
              title: "短信模板自动审核",
              contentList: [
                {
                  content: "无缝对接短信服务商，短信模块变更后自动审核，保证易用。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/dxfw_1.png"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            // {
            //     img: require("@/assets/saasImg/mianfei@2x.png"),
            //     title: '免费试用',
            //     msg: '支持免费试用，满意后再订阅。',
            // },
          ],
        },
        // 保险服务商入驻
        "insurance_service_provider": {
          //产品功能
          productFeatures: [
            {
              title: "提供在线保险服务",
              contentList: [
                {
                  content: "入驻平台，直达海量发货人，为发货人提供在线保险服务，助您实现保险业务规模化增长 投保",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/insurance.jpg"),
                },
              ]
            },
            {
              title: "EDI对接",
              contentList: [
                {
                  content: "为提高保险业务运营效率，可进行EDI对接，简化系统操作",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/edi.jpg"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            {
              img: require("@/assets/saasImg/features/easy.png"),
              title: '规模化',
              msg: '通过平台直达海量发货人，更容易实现保险业务规模化增长',
            },
            {
              img: require("@/assets/saasImg/gcfw_2.png"),
              title: '便捷化',
              msg: '通过EDI对接，简化系统操作',
            },
          ],
        },
        //车辆资源
        /*   "data_online_shunt": {
                       //产品功能
                       productFeatures: [
                           {
                               title: "在线调车服务",
                               contentList: [
                                   {
                                       content: "为用户提供车辆及报价，调车过程高效且透明。",
                                   },
                               ],
                               "select": true,
                               imgs: [
                                   {
                                       img: require("@/assets/saasImg/zxdc_1.png"),
                                   },
                               ]
                           },
                       ],
                       //产品优势
                       params: [
                           {
                               img: require("@/assets/saasImg/duoqudao@2x.png"),
                               title: '多渠道',
                               msg: '对接多种车源渠道，丰富的资源匹配，保证车源稳定可靠',
                           },
                           {
                               img: require("@/assets/saasImg/keshihua@2x.png"),
                               title: '可视化',
                               msg: '地图可视化调车，操作直观透明，实现高效车辆调度',
                           },
                       ],
                   },*/
        //共创服务
        "serve_creation": {
          //产品功能
          productFeatures: [
            {
              title: "产业园运营服务",
              contentList: [
                {
                  content: "产业园运营公司相关服务主要包括：协助企业与政府、\n" +
                      "税局衔接； 税收奖励、补贴申报兑现；网络货运系统和物流技术服务；",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/gcfw_1.png"),
                },
              ]
            },
            {
              title: "产业链资源对接",
              contentList: [
                {
                  content: "融资资源对接；创业资源支持；工商注册、税务登\n" +
                      "记办理； 财务代账公司、 中介咨询等资源衔接；物业管理沟通；",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/gcfw_2.png"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            // {
            //     img: require("@/assets/saasImg/mianfei@2x.png"),
            //     title: '免费试用',
            //     msg: '支持免费试用，满意后再订阅。',
            // },
          ],
        },
        //网络货运申报协助
        "serve_online_help": {
          //产品功能
          productFeatures: [
            {
              title: "全流程支持",
              contentList: [
                {
                  content: "全流程支持，包括：申报材料编写，线上资料提交，现场尽调协助，道路运输证办理等服务，帮助客户快速完成申报。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/sbxz_1.png"),
                },
              ]
            },
            {
              title: "多省份案例",
              contentList: [
                {
                  content: "我们已帮助全国20多个省份的用户成功申报网络货运，在系统搭建、线上核验和现场答辩等过程中，都积累了丰富经验。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/sbxz_2.png"),
                },
              ]
            },
            {
              title: "附带运营咨询",
              contentList: [
                {
                  content: "我司是全国首批试点单位，在团队努力下 ，曾获得省内排名第一，全国排名第九，申报过程更有保障。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/sbxz_3.png"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            // {
            //     img: require("@/assets/saasImg/mianfei@2x.png"),
            //     title: '免费试用',
            //     msg: '支持免费试用，满意后再订阅。',
            // },
          ],
        },
        //网络货运等保复测
        "serve_online_retest": {
          //产品功能
          productFeatures: [
            {
              title: "平台软件通过多省份等保认证",
              contentList: [
                {
                  content: "目前我司的网络货运产品已通过全国20多个省份的等保三级测评，软件产品不断迭代升级安全性高，已基本杜绝了高中危漏洞，可快速完成复测。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/dbfc_1.png"),
                },
              ]
            },
            {
              title: "丰富的等保测评服务经验",
              contentList: [
                {
                  content: "在全国200多家用户的测评过程中，积累了大量宝贵经验，帮助客户实现最优化的安全配置，避免过度投入。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/dbfc_2.png"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            // {
            //     img: require("@/assets/saasImg/mianfei@2x.png"),
            //     title: '免费试用',
            //     msg: '支持免费试用，满意后再订阅。',
            // },
          ],
        },
        //网络货运平台运营咨询
        "serve_online_freight": {
          //产品功能
          productFeatures: [
            // {
            //     title: "运单管理",
            //     contentList: [
            //         {
            //             content: "运单管理**********************************************************************",
            //         },
            //     ],
            //     "select": true,
            //     imgs: [
            //         {
            //             img: require("@/assets/saasImg/online_shunting_1.png"),
            //         },
            //         {
            //             img: require("@/assets/saasImg/online_shunting_2.png"),
            //         },
            //     ]
            // },
          ],
          //产品优势
          params: [
            // {
            //     img: require("@/assets/saasImg/mianfei@2x.png"),
            //     title: '免费试用',
            //     msg: '支持免费试用，满意后再订阅。',
            // },
          ],
        },
        //ICP电信增值年审
        "serve_ICP": {
          //产品功能
          productFeatures: [
            {
              title: "可靠的年审服务",
              contentList: [
                {
                  content: "平台优选ICP电信增值年审服务商，帮助用户快速可靠的完成ICP年审，保障业务顺利运行。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/icp_1.png"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            // {
            //     img: require("@/assets/saasImg/mianfei@2x.png"),
            //     title: '免费试用',
            //     msg: '支持免费试用，满意后再订阅。',
            // },
          ],
        },
        //网络货运服务商
        "service_provider": {
          //产品功能
          productFeatures: [
            {
              title: "在线签约",
              contentList: [
                {
                  content: "服务对比，在线签约，资质认证，安全可靠。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/zxqy_01.jpg"),
                },
              ]
            },
            {
              title: "支付结算",
              contentList: [
                {
                  content: "在线运费支付，自动认款核销，发票开具。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/zxjs_01.jpg"),
                },
              ]
            },
            {
              title: "服务商调车",
              contentList: [
                {
                  content: "网货服务商提供调车服务，高率降本。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/fwsdc_01.jpg"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            {
              img: require("@/assets/saasImg/zyxz.png"),
              title: '自由选择',
              msg: '为用户提供更多的网络货运服务选择。',
            },
            {
              img: require("@/assets/saasImg/jbzx.png"),
              title: '降本增效',
              msg: '通过平台直达服务商，节省系统对接时间。',
            },
          ],
        },
        //网络货运服务商入驻
        "service_provider_sub": {
          //产品功能
          productFeatures: [
            {
              title: "自主管理",
              contentList: [
                {
                  content: "交易规则，服务费率自主配置。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/自主管理.jpg"),
                },
              ]
            },
            {
              title: "优质客源",
              contentList: [
                {
                  content: "用户实名认证，安全可靠。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/优质客源.jpg"),
                },
              ]
            },
            {
              title: "在线结算",
              contentList: [
                {
                  content: "在线费用计算，快捷高效。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/在线结算.jpg"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            {
              img: require("@/assets/saasImg/共生共赢.png"),
              title: '共生共赢',
              msg: '与共生一起成长，提升行业的服务价值，实现行业生态的共生共赢。',
            },
            {
              img: require("@/assets/saasImg/良性机制.png"),
              title: '良性机制',
              msg: '线上签约，过程开放透明，强化服务提高效能，建立良性竞争环境。',
            },
          ],
        },
        //数字招投标
        "e-bidding": {
          //产品功能
          productFeatures: [
            {
              title: "支持多场景",
              contentList: [
                {
                  content: "运输方式：支持公路运输、水路运输。",
                },
                {
                  content: "业务周期：支持年度招标、季度招标、临时订单竞标。",
                },
                {
                  content: "服务类型：支持承运商、服务商、个体运力。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/ebidding-01.png"),
                },
              ]
            },
            {
              title: "候选承运商",
              contentList: [
                {
                  content: "在线发布候选承运商公告，支持资质条件自定义组合，满足企业筛选承运商资质个性化要求，平台智能推送意向承运商，在线完成资质认定和管理。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/ebidding-02.jpg"),
                },
              ]
            },
            {
              title: "在线招标",
              contentList: [
                {
                  content: "发布按年度、月度、周期性线路招标，支持按趟次、吨方阶梯，各种复杂商务标报价方式，完整的招标流程，包括公告发布、承运商邀请、商务标评定、在线报价、开标评审、流程审批、中标公示等。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/ebidding-03.jpg"),
                },
              ]
            },
            {
              title: "订单竞标",
              contentList: [
                {
                  content: "快速发布整车、零担竞标订单，系统智能匹配服务商、个体运力，推送短信、微信待报价消息，投标方报价后自动报价排名，方便招标方快速决策中标服务商。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/ebidding-04.jpg"),
                },
              ]
            },
            {
              title: "流程闭环",
              contentList: [
                {
                  content: "招标订单中标，支持承运商、服务商、个体运单三种运作模式，中标订单自动同步到运输订单模块，同时记录订单成本以及中标用户，一键完成订单生成运单。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/ebidding-05.jpg"),
                },
              ]
            },
            {
              title: "数据安全",
              contentList: [
                {
                  content: "招标过程中，数据加密严防报价外泄，系统账号访问、招标菜单点击记录全部留存，支持账号全流程操作记录追查。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/ebidding-06.jpg"),
                },
              ]
            },

          ],
          //产品优势
          params: [
            {
              img: require("@/assets/saasImg/features/intelligent.png"),
              title: '智能',
              msg: '可以根据客户需求，配置化各种复杂报价格式，支持商务标、技术标组合排名，以及拦标价、防呆价限制。',
            },
            {
              img: require("@/assets/saasImg/features/stablize.png"),
              title: '便捷',
              msg: '招标方支持批量导入、手工录单、EDI接口，投标方小程序端接收消息快速响应报价。',
            },
            {
              img: require("@/assets/saasImg/features/convenient.png"),
              title: '实用',
              msg: '支持中标后同步运输系统支持业务运作，同样支持运输订单推送招标系统竞价。',
            },
          ],
        },
        // 数据上链
        "block_chain": {
          //产品功能
          productFeatures: [
            {
              title: "数据上链",
              contentList: [
                {
                  content: "业务数据上链，支持订单数据、运单数据、招投标业务数据上链，上链数据灵活可配置",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/dataChain.png"),
                },
              ]
            }
          ],
          //产品优势
          params: [
            {
              img: require("@/assets/saasImg/features/security.png"),
              title: '增信',
              msg: '为关键业务数据增信，建立可信的运行环境',
            },
            {
              img: require("@/assets/saasImg/features/easy.png"),
              title: '可配置',
              msg: '支持灵活配置上链数据',
            },
          ],
        },

        // 供应链控制塔
        "supply_chain_tower": {
          //产品功能
          productFeatures: [
            {
              title: "KPI管理",
              contentList: [
                {
                  content: "支持对承运商进行KPI管控，自定义KPI指标参数，在线KPI考核。",
                },
              ],
              "select": true,
              imgs: [
                {
                  img: require("@/assets/saasImg/details/tower/KPI管理.jpg"),
                },
              ]
            },
            {
              title: "可视化监控",
              contentList: [
                {
                  content: "支持供应链运行可视化监控，在线监管订单执行、工厂负荷、异常管控，线路成本变化、销售货物占比等，为高层决策提供数据支撑。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/details/tower/可视化监控.jpg"),
                },
              ]
            },
            {
              title: "客户跟单",
              contentList: [
                {
                  content: "支持收货人手机查单，实时跟踪订单评审、发运情况，并支持在线签收、回单上传，提升收货人体验。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/details/tower/客户跟单.jpg"),
                },
              ]
            },
            {
              title: "快速下单",
              contentList: [
                {
                  content: "支持OpenApi接口、Excel批量导入和手工多种方式录入销售订单，同时具备地址 信息记忆功能，并支持安卓、IOS、小程序录单。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/details/tower/快速下单.png"),
                },
              ]
            },
            {
              title: "协同评审",
              contentList: [
                {
                  content: "支持销售、财务、物流多部门协同在线评审，提升组织协同能力，加快订单执行效率。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/details/tower/协同评审.jpg"),
                },
              ]
            },
            {
              title: "异常管控",
              contentList: [
                {
                  content: "实时监管订单评审、发运、跟踪、送达、签收等过程，自定义异常指标，异常信息实时提醒跟进处理。",
                },
              ],
              "select": false,
              imgs: [
                {
                  img: require("@/assets/saasImg/details/tower/异常管控.jpg"),
                },
              ]
            },
          ],
          //产品优势
          params: [
            // {
            //   img: require("@/assets/saasImg/features/可视化.png"),
            //   title: 'KPI指标自定义',
            //   msg: '供应链执行指标个性化设置，异常实时提醒。',
            // },
            {
              img: require("@/assets/saasImg/features/跟单.png"),
              title: '客户跟单',
              msg: '支持灵活配置上链数据',
            },
            {
              img: require("@/assets/saasImg/features/可视化.png"),
              title: '可视化',
              msg: '运作车辆可视化监控、仓库到车进场可视化管理，供应链大屏全局把控，提升管理能力。',
            },
            {
              img: require("@/assets/saasImg/features/协同高效.png"),
              title: '协同高效',
              msg: '销售订单在线多部门协同评审、物流计划与物流资源高效协同。',
            },
          ],
        },
      };
      this.localData = products[priductCode];
    },

    //选中特性
    createSelFeature(itemSel) {
      this.selFeature = itemSel;
      this.localData.productFeatures.forEach(item => {
        if (item.title == itemSel.title) {
          item.select = true;
          this.featurImgNull = true
          this.featurListOne = itemSel;
          this.featurImgList = itemSel.imgs;
          if (this.featurImgList == undefined) {
            this.featurImgNull = false
          }
          if (this.featurImgList.length == 1) {
            this.featurImgs = false
          }
          if (this.featurImgList.length > 1) {
            this.featurImgs = true
          }
        } else {
          item.select = false;
        }
      });
    },

    //推荐搭配跳转页面
    goSup(item) {
      var params = {productCode: item.code};
      if (item.id) {
        var url = window.location.protocol + "//" + window.location.host + "/saas.html#/saasDetail/" + item.id + "?spid=" + item.id + "&code=" + item.code;
        window.open(url);
      } else {
        aEntityAPI
            .saasRequest("UserProductSubscribeService", "queryProductByCode", params)
            .then((res) => {
              this.grid_loading = false;
              if (res.data.success) {
                var url = window.location.protocol + "//" + window.location.host + "/saas.html#/saasDetail/" + res.data.data + "?spid=" + res.data.data + "&code=" + item.code;
                window.open(url);
              }
            })
      }
    },

    //产品详情
    getSaasProDetail() {
      let that = this;
      var params = {proId: this.curId};
      aEntityAPI
          .saasRequest("UserProductSubscribeService", "getSaasDetail", params)
          .then((res) => {
            this.grid_loading = false;
            if (res.data.success) {
              var dataDict = res.data.data;
              if (dataDict.product != null) {

                document.title = dataDict.product.spName;

                this.product = dataDict.product;
                this.experience = this.product.experience;

                if (this.product.spCode == 'resource_dri_levy') {
                  this.qr_code.qr_img = this.qr_code.xufang;
                }
                if (this.product.spCode == 'resource_bank') {
                  this.qr_code.qr_img = this.qr_code.weiziling;
                }
                if (this.product.spCode == 'resource_signature') {
                  this.qr_code.qr_img = this.qr_code.zhanglinlin;
                }
                if (this.product.spCode == 'resource_etc_ticket') {
                  this.qr_code.qr_img = this.qr_code.zhaoyufeng;
                }
                if (this.product.spCode == 'resource_oil_card' || this.product.spCode == 'resource_insurance') {
                  this.qr_code.qr_img = this.qr_code.xiekangfeng;
                }
                if (that.product.relyList != null && that.product.relyList.length > 0) {
                  that.relyList = that.product.relyList;
                }
              }
            } else {
              this.$message({
                showClose: true,
                message: res.data.msg ? res.data.msg : "查询产品明细数据失败",
                type: "warning",
              });
            }
          })
          .catch((res) => {
            this.grid_loading = false;
          });
    },

    //推荐搭配
    getsupProList(productCode) {
      var supList = [];
      this.productCode.forEach(item => {
        if (item.code == productCode) {
          item.productMatch.forEach(e => {
            // if (e.code.split("_")[0] == 'data') {
            //     e.name = '数据·' + e.name;
            // }
            // if (e.code.split("_")[0] == 'tool') {
            //     e.name = '工具·' + e.name;
            // }
            // if (e.code.split("_")[0] == 'serve') {
            //     e.name = '服务·' + e.name;
            // }
            // if (e.code.split("_")[0] == 'resource') {
            //     e.name = '资源·' + e.name;
            // }
            supList.push(e)
          });
        }
      });
      this.supProList = supList;
    },

  },

  filters: {
    //文字数超出时，超出部分使用...
    ellipsisTitle(value) {
      if (!value) return ''
      if (value.length > 14) {
        return value.slice(0, 14) + '...'
      }
      return value
    },

    ellipsisRemark(value) {
      if (!value) return ''
      if (value.length > 92) {
        return value.slice(0, 92) + '...'
      }
      return value
    }
  }
};
</script>
<style>
.saas_detail_body_pro_sup_banner_null {
  font-size: 30px;
  color: #b1abab;
  padding: 50px 0px;
  background-color: #F3F5F8;
  width: 100%;
  text-align: center;
}

.product_features_is_null {
  height: 300px;
}

.product_features_is_not_null {
  /*height: 880px;*/
  height: 990px;
}

.saas_detail_body {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.saas_detail_body_top_info {
  position: relative;
  background-color: #d9d9d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /*width: 100%;*/
  height: 550px;
  background-image: url(../assets/saasImg/banner_a.png);
  /*background-size: 100%;*/
  /*min-width: 1348px;*/
  margin-top: 55px;
}

.saas_detail_body_top_left_info {
  margin-left: 295px;
  z-index: 5;
  height: 375px;
}

.saas_detail_body_top_left_info_title_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.saas_detail_body_top_left_info_title_view_lab {
  color: #505050;
  font-size: 58px;
  font-weight: bold;
}

.saas_detail_body_top_left_info_title_view_exp {
  color: rgba(42, 130, 228, 1);
  border-radius: 18px;
  line-height: 150%;
  border: rgb(0 80 218) solid 1px;
  margin-left: 30px;
  padding-top: 12px;
  margin-top: 2px;
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #0068E9;
  line-height: 9px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  /*width: 90px;*/
  height: 21px;
}

.saas_detail_body_top_left_info_remark {
  margin-top: 10px;
  color: rgba(80, 80, 80, 1);
  font-size: 24px;
  line-height: 150%;
  text-align: left;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  /*margin-bottom: 15px;*/
  /*width: 75%;*/
  width: 670px;
}

.saas_detail_body_top_left_info_price {
  color: rgba(80, 80, 80, 1);
  font-size: 20px;
  line-height: 150%;
  text-align: left;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.saas_detail_body_top_left_info_price_develop {
  margin-top: 30px;
}

.saas_detail_body_price_color {
  color: #ff502e;
  font-size: 36px;
  padding-left: 2px;
}

.saas_detail_body_product_right_content {
  margin-bottom: 5px;
}

.saas_detail_body_top_left_info_btnview {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /*margin-top: 20px;*/
}

.saas_detail_body_sup_product_view {
  margin-left: 230px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: -33px;
}

.saas_detail_body_top_left_info_btnview_button:hover {
  cursor: pointer;
}

.saas_detail_body_sup_product_view_title {
  font-size: 22px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #539BCC;
  line-height: 34px;
}

.saas_detail_body_sup_product_view_list {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.saas_detail_body_product_tab_item:hover {
  cursor: pointer;
  box-shadow: 0px 0px 5px #484345;
}

.saas_detail_body_product_tab_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  padding: 10px 15px;
  background-color: #FFFFFF;
  border: 1px solid #929292;
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #606060;
  line-height: 9px;
}

.saas_detail_body_product_tab_item:hover .saas_detail_body_product_tab_item_icon_lab {
  color: #ffffff;
}

.saas_detail_body_product_tab_item:hover .saas_detail_body_product_tab_item_lab {
  color: #ffffff;
}

.saas_detail_body_product_tab_item_icon_lab {
  padding: 2px 3px;
  background-color: #e5e5e5;
  color: #505050;
  font-size: 9px;
}

.saas_detail_body_product_tab_item_lab {
  color: #2a82e4;
  font-size: 14px;
}

.saas_detail_body_top_right_img {
  width: 100%;
  z-index: 3;
  left: 0;
  top: 0;
  position: absolute;
}

.saas_detail_body_discount {
  height: 80px;
  background-color: #c6c6c6;
  width: 100%;
}

.saas_detail_body_product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: #F3F5F8;
  height: auto;
  /*height: 880px;*/
  /*margin-top: 30px;*/
}

.saas_detail_body_product_tab_title {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  color: rgba(80, 80, 80, 1);
  text-align: center;
  font-size: 36px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #000000;
  height: 50px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.saas_detail_body_product_tab_title_one {
  width: 100px;
  height: 1px;
  background-color: #DCDCDE;
}

.saas_detail_body_product_tab_title_three {
  font-size: 36px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #000000;
  margin: 0 10px;
}

.saas_detail_body_product_tab_title_two {
  width: 4px;
  height: 4px;
  background-color: #DCDCDE;
  border-radius: 2px;
  margin-top: 2px;
}

.saas_detail_body_product_body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 80%;
  min-width: 1200px;
  /*min-height: 480px;*/
  height: auto;
  margin-top: 50px;
  margin-bottom: 30px;
}

.saas_detail_body_product_left_list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 230px;
  height: 100%;
  background-color: #F3F5F8;
}

.saas_detail_body_featur_item {
  color: #4D5A73;
  font-size: 14px;
  text-align: center;
  min-width: 190px;
  margin-bottom: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 4px;
}

.saas_detail_body_featur_item_t {
  background: -webkit-linear-gradient(top, rgba(233, 237, 243, 1), rgba(246, 248, 250, 1));
}

.saas_detail_body_featur_item_one {
  min-width: 231px;
  border-right-style: none;
  z-index: 2;
  background-color: #F4F6F9;
  border: 2px solid #FFFFFF;
  border-right-style: none;
}

.saas_detail_body_featureTitleTive {
  width: 100%;
}

.saas_detail_body_product_right {
  flex: 2;
  color: rgba(80, 80, 80, 1);
  background-color: #F4F6F9;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 2px solid #FFFFFF;
  box-shadow: 4px 4px 8px 8px rgba(233, 237, 243, 1);
  /*height: 650px;*/
  /*height: 760px;*/
  /*min-height: 500px;*/
  height: auto;
  padding-bottom: 20px;
}

.saas_detail_body_product_right_imgs {
  /*flex: 2;*/
  width: 80%;
  /*margin: 30px auto;*/
  margin: 0% 10%;

}

.saas_detail_body_product_right_img {
  width: 97%;
  margin: 3%;
  background-size: 100% 100%;
}

.saas_detail_body_hidden_view {
  width: 0px;
  height: 0px;
  overflow: hidden;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.saas_detail_body_product_right_point {
  width: 8px;
  height: 8px;
  background-color: #9BA6B7;
  border-radius: 4px;
  float: left;
  margin-top: 5px;
}

.saas_detail_body_product_right_content_text {
  font-size: 15px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #4D5A73;
  margin-left: 20px;

  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.saas_detail_body_featureTive {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.saas_detail_body_product_right_lab {
  color: rgba(80, 80, 80, 1);
  font-size: 14px;
  height: 40px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
  text-align: left;
}

.saas_detail_body_pro_sup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 30px;
}

.saas_detail_body_pro_sup_banner {
  text-align: center;
  margin-bottom: 40px;
}

.saas_detail_body_pro_sup_banner_img {
  margin-top: 36px;
}

.saas_detail_body_pro_sup_banner_zero {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.saas_detail_body_pro_sup_banner_two {
  font-family: Source Han Sans SC;
  font-weight: 600;
  color: #333333;
  line-height: 40px;
  font-size: 25px;
  margin-top: 20px;
}

.saas_detail_body_pro_sup_banner_three {
  width: 310px;
  height: 84px;
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #333333;
  margin: 20px auto;
  /*超出换行*/
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;

  line-height: 26px;
}

.saas_detail_body_product_right_text {
  margin-top: 28px;
  margin-left: 64px;
  font-size: 20px;
  color: #F4F6F9;
  font-family: Source Han Sans SC;
  background-color: rgb(244, 246, 249);
  margin-bottom: 10px;
}

.saas_detail_body_featur_item {
  cursor: pointer;
}

.saas_detail_body_top_left_info_one {
  float: right;
  z-index: 5;
}

.saas_detail_body_top_left_info_remark_one {
  font-size: 18px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #606060;
  line-height: 30px;
  /*width: 720px;*/
  /*height: 87px;*/
  max-height: 87px;
}

.saas_detail_body_pro_sup_banner_one {
  margin-left: 10px;
  width: 400px;
  min-width: 316px;
  background: #FFFFFF;
  border: 1px solid #DCDCDC;
  box-shadow: 0px 2px 43px 0px rgba(21, 113, 179, 0.07);
  opacity: 0.97;
  border-radius: 8px;
}

.saas_detail_body_discount_body {
  width: 100%;
  position: absolute;
  top: 440px;
  left: 0px;
  background-color: #ffffff66;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 110px;
  z-index: 12;
}

.saas_detail_body_discount_body_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 100%;
}

.saas_detail_body_discount_body_item:hover {
  background-color: #ffffff66;
  cursor: pointer;
}

.saas_detail_body_discount_body_item:hover .saas_detail_body_discount_body_item_top_title {
  color: #2468f2;
}

.saas_detail_body_discount_body_item_top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.saas_detail_body_discount_body_item_top_title {
  color: #000;
  font-size: 24px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  margin-right: 5px;
}

.saas_detail_body_discount_body_item_bottom {
  color: #666666;
  font-size: 16px;
  width: 85%;
  height: auto;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
  word-wrap: break-word;
  overflow: hidden;
}

#saas_detail_body_product img {
  max-width: 900px;
}

.saas_detail_body_top_left_info_btnview_button .goto_subscribe {
  width: 130px;
  height: 38px;
  font-size: 22px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  background-color: #0050DA;
  color: #FFFFFF;
  vertical-align: middle;
}

.saas_detail_body_top_left_info_btnview_button .goto_try {
  width: 133px;
  height: 37px;
  font-size: 22px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  vertical-align: middle;
}

.saas_detail_body_top_left_info_btnview_button_develop {
  height: 100px;
}

.saas_detail_body_top_left_info_btnview_button .develop_button {
  width: 133px;
  height: 37px;
  font-size: 22px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  background-color: #8c939d;
  border-radius: 18.5px;
  color: #FFFFFF;
  padding-bottom: 7px
}

.saas_detail_body_top_left_info_btnview_button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.saas_detail_body_top_left_info_btnview_button .develop_button_title {
  margin-left: 12px;
  color: #539BCC;
  font-size: 22px;
  font-family: Source Han Sans SC;
}

.saas_detail_body_top_left_info_btnview_button .develop_button_qr_code_min {
  margin-left: 12px;
  margin-top: 5px;
}

.saas_detail_body_top_left_info_btnview_button .develop_button_qr_code_max {
  margin-left: 12px;
}

.saas_detail_body_top_left_info_btnview_button:hover {
  cursor: pointer;

}

.product_info_rely:hover {
  cursor: pointer;

}

.saas_detail_body_top_left_info_btnview_button .develop_button_qr_code_max_mouseover {
  opacity: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.saas_detail_body_top_left_info_btnview_button .develop_button_qr_code_max_mouseleave {
  opacity: 1;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.qr_code_button_top {
  margin-top: 30px;
}
</style>