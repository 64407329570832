import Vue from 'vue'
import Router from 'vue-router'

import saasIndex from './saasIndex.vue'
import saasDetail from './saasDetail.vue'
import saasActive from "./saasActive.vue"
import saasSubscribeTab from "../project/common/views/saasSubscribe/saasSubscribeTab.vue"

Vue.use(Router)


export default new Router({
    routes: [

        {path: '*', redirect: '/saas'},
        {//系统入口页面，
            path: '/saas/:searchValue?',
            name: 'saas',
            props: true,
            meta: {
                title: 'SaaS首页'
            },
            component: saasIndex

        },

        {
            path: '/saasDetail/:spId?',
            name: 'saasDetail',

            meta: {
                title: 'SaaS详情'
            },
            component: saasDetail

        },
        {
            path: '/saasActive',
            name: 'saasActive',

            meta: {
                title: '活动详情'
            },
            component: saasActive

        },
        {
            path: '/saasSubscribeTab/:id?/:code?',
            name: 'saasSubscribeTab',

            meta: {
                title: '产品订阅'
            },
            component: saasSubscribeTab

        },


    ]
})
